import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ModalProvider } from "./components/utilities/ModalContext";
import { UserProvider } from "./components/utilities/UserContext";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.NODE_ENV === "development" ? process.env.REACT_APP_PUBLISHABLE_KEY_TEST : process.env.REACT_APP_PUBLISHABLE_KEY
    );
  }
  return stripePromise;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={getStripe()}>
      {/* <Elements stripe={stripePromise}> */}
      <Router>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);
