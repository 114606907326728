import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosApi } from "../utilities/axiosConfig";
import { Link, useHistory } from "react-router-dom";

// SCHEMA DE VÉRIFICATION
const schema = yup.object().shape({
  email: yup.string().email("Adresse e-mail invalide").required("Veuillez entrer une adresse e-mail."),
  password: yup.string().required("Veuillez entrer un mot de passe.").min(8, "Minimum 8 carractères.").max(100, "Maximum 100 carractères."),
});

export const SuccessLogin = ({ setOpen, redirect }) => {
  const history = useHistory();
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="flex justify-center items-center w-full">
        <div
          // style={{ color: "#31B326" }}
          className="text-xl uppercase font-bold text-green-500 sm:text-2xl text-center my-5"
        >
          Connexion réussie
        </div>
        <svg
          // style={{ color: "#31B326" }}
          xmlns="http://www.w3.org/2000/svg"
          className="text-green-500 ml-2 h-8 w-8 sm:h-9 sm:w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <button
        className="h-12 text-center focus:outline-none cursor-pointer w-full mt-2 mb-4 pl-4 text-white rounded-lg transition duration-300 bg-green-500 hover:bg-green-600"
        onClick={() => {
          setOpen(false);
          if (redirect) history.push("/mon-espace");
        }}
      >
        {redirect ? "MON ESPACE" : "FERMER"}
      </button>
    </div>
  );
};

// COMPOSANT
function LoginForm({ hide, setSuccess, setLoginInfos, loader, setLoader }) {
  // const [logged, setLogged] = useState(false);
  const [error, setError] = useState(false);
  // const [loader, setLoader] = useState(false);

  // VÉRIFICATION
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // ENVOI DU FORMULAIRE
  const onSubmit = (data) => {
    setLoader(true);
    axiosApi
      .post(`/auth/login`, { data }, { withCredentials: true })
      .then((res) => {
        if (res.data.success === true) {
          setTimeout(() => {
            setSuccess({
              user: {
                name: res.data.name,
                email: res.data.email,
                refreshToken: res.data.refreshToken,
                accessToken: res.data.accessToken,
                offer: res.data.offer,
              },
            });
            setLoader(false);
          }, 1000);
          // setLogged(true);
        } else {
          if (res?.data?.error === "Email not verified") {
            setError("not verified");
          } else {
            setError("no detail");
          }
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // STYLES
  const inputStyle = "h-12 my-2 w-full pl-4 rounded-lg bg-gray-50 border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none";
  const messageStyle = (errors) => (errors ? "block -mb-1 text-sm pl-0.5" : "hidden");

  // FORMULAIRE
  return (
    <div>
      {/* {!logged ? ( */}
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div>
            <div style={{ color: "red" }} className={messageStyle(errors.email)}>
              {errors.email && errors.email.message}
            </div>
            <input
              type="text"
              name="email"
              ref={register}
              placeholder="Adresse e-mail"
              style={{ border: errors.email && "1px solid red" }}
              className={inputStyle}
              onChange={() => setError(false)}
            />
          </div>
          <div>
            <div style={{ color: "red" }} className={messageStyle(errors.password)}>
              {errors.password && errors.password.message}
            </div>
            <input
              type="password"
              name="password"
              ref={register}
              placeholder="Mot de passe"
              style={{ border: errors.password && "1px solid red" }}
              className={inputStyle}
              onChange={() => setError(false)}
            />
            <div style={{ color: "red" }} className={messageStyle(error)}>
              {error === "not verified"
                ? "L'adresse e-mail associée à ce compte n'a pas été vérifiée, veuillez consulter votre boite mail"
                : "Email ou mot de passe incorrect"}
            </div>
          </div>
          <div
            className={`relative h-12 w-full mt-2 mb-1 pl-4 text-white rounded-lg transition duration-300 flex justify-center items-center ${
              loader ? "bg-green-500" : "bg-purple-elec hover:bg-purple-900"
            }`}
          >
            <input type="submit" value="" className={`absolute z-10 w-full h-full bg-transparent ${loader ? "cursor-default" : "cursor-pointer"}`} />
            {loader ? (
              <>
                <p>CHARGEMENT</p>
                <div className="spinner"></div>
              </>
            ) : (
              <p>CONNEXION</p>
            )}
          </div>
        </form>
        <Link to="/mot-de-passe-oublie" onClick={hide} className="block text-xs text-gray-400 w-full text-center my-3 underline">
          Mot de passe oublié
        </Link>
      </div>
      {/* ) : (
        <SuccessLogin hide={hide} />
      )} */}
    </div>
  );
}

export default LoginForm;
