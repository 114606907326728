import React from "react";

const MentionsLegales = () => {
  return (
    <>
      <h1 className="title">MENTIONS LEGALES</h1>
      <div className="infos">
        <div className="title2">Responsable de la publication</div>
        <br />
        Babac’Cool
        <br />2 Rue de la Commanderie
        <br />
        78350 LES LOGES EN JOSAS (France)
        <br />
        N° de SIRET 892 648 668 00010
        <br />
        <a href="mailto:contact.client@babaccool.fr">
          contact.client@babaccool.fr
        </a>
      </div>
      <div className="infos">
        <div className="title2">Hébergement</div>
        <br />
        O2switch
        <br />
        222 Boulevard Gustave Flaubert
        <br />
        63000 Clermont-Ferrand
        <p>
          Administratif :{" "}
          <a href="mailto:contact.client@babaccool.fr">
            contact.client@babaccool.fr
          </a>
        </p>
      </div>
      <div className="infos">
        <div className="title2">Conditions générales</div>
        <br />
        Conditions générales d'utilisation
        <br />
        Conditions générales de vente
      </div>
    </>
  );
};

export default MentionsLegales;
