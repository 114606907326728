import React, { useState, useEffect } from "react";
import { Link, Route, useLocation, Switch, useParams, useHistory } from "react-router-dom";
import DisplayFiche from "./DisplayFiche";
import { AchatModal } from "./AchatModal";
import { UserContext } from "../../components/utilities/UserContext";
import useViewport from "../../components/utilities/useViewport";
import diplome from "../../assets/diplome.svg";
import CheckoutForm from "../../components/utilities/CheckoutForm";
import Modal from "../../components/utilities/Modal";
import Auth from "../../components/Auth/Auth";
import { ModalContext } from "../../components/utilities/ModalContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Helmet } from "react-helmet";
import { axiosApi } from "../../components/utilities/axiosConfig";
import { useQuery } from "react-query";

const matiereColor = (slug) => {
  switch (slug) {
    case "physique-chimie":
      return "#97ADDC";
    case "philosophie":
      return "rgb(245, 176, 107)";
    case "grand-oral":
      return "rgb(120, 211, 149)";
    case "hggsp":
      return "rgb(189, 114, 250)";
    case "sciences-economiques-et-sociales":
      return "rgb(250, 148, 228)";
    default:
      return "matiere-bg";
  }
};

const matiereBg = (slug) => {
  return `linear-gradient(135deg, rgb(249, 250, 251) 60%, ${matiereColor(slug)} 60%)`;
};

const CustomLink = ({ to, children, slug_mat }) => {
  const { width } = useViewport();
  return (
    <Link
      to={to}
      style={{ background: width > 640 && matiereBg(slug_mat) }}
      className="relative bg-gray-50 flex justify-start items-center mx-2 sm:mx-4 my-4 border rounded-lg border-gray-200 animation duration-300 sm:hover:opacity-80 cursor-pointer"
    >
      <div style={{ maxWidth: "calc(100% - 4rem)" }} className="text-base mg:text-lg lg:text-lg ml-4 my-5 z-10">
        <h3 className="truncate">{children}</h3>
      </div>
      <div style={{ background: matiereBg(slug_mat) }} className="sm:hidden absolute top-2 bottom-0 right-0 width w-14 rounded-br-lg"></div>
    </Link>
  );
};

const Nav = ({ categorie, location, children, end = false }) => {
  const locationIsFiche = useLocation().pathname.split("/")?.[4] !== undefined;
  const isHome = useLocation().pathname.split("/")?.[2] === undefined;
  return (
    <Link
      to={`/${location}${categorie}`}
      onClick={(e) => {
        if (end && !locationIsFiche) {
          e.preventDefault();
        }
      }}
      className={`flex items-center h-10 animation duration-300 cursor-default z-10 ${
        (!end || locationIsFiche) && "sm:hover:opacity-70 cursor-pointer"
      } ${end && "truncate"}`}
    >
      <h2
        className={`pl-3 pr-2 sm:text-lg lg:text-lg text-gray-800 ${
          end && !isHome ? "truncate" : "whitespace-nowrap flex justify-center items-center"
        }`}
      >
        {children}
      </h2>
      {!end && (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      )}
    </Link>
  );
};

const NavFiches = ({ data, location }) => {
  const currentLocation = useLocation().pathname.split("/").slice(2, 4);
  let matiere = null;
  let chapitre = null;
  if (currentLocation.length > 0) {
    matiere = data.find((matiere) => matiere.slug === currentLocation[0]).name;
  }
  if (currentLocation.length > 1) {
    chapitre = data.find((matiere) => matiere.slug === currentLocation[0]).chapitres.find((chapitre) => chapitre.slug === currentLocation[1]).name;
  }
  return (
    <div
      style={{
        boxShadow: "",
        // "0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      }}
      className="bg-gray-100 border-b border-t border-gray-200"
    >
      <div className="flex flex-nowrap w-full overflow-hidden max-w-screen-xl sm:pl-2 mx-auto">
        <Nav categorie={""} end={!matiere && true} location={location}>
          Accueil
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-gray-800" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </span>
        </Nav>
        {matiere && (
          <Nav categorie={`/${currentLocation[0]}`} end={!chapitre && true} location={location}>
            {matiere}
          </Nav>
        )}
        {chapitre && (
          <Nav categorie={`/${currentLocation[0]}/${currentLocation[1]}`} end={true} location={location}>
            {chapitre}
          </Nav>
        )}
      </div>
    </div>
  );
};

const BuyPackLink = ({ slug_mat, setAchat, type, pricing, id, owned, children }) => {
  const { width } = useViewport();
  const mobile = width < 640;
  const possiblePurchase = !owned && pricing !== null;
  return (
    <>
      {possiblePurchase && (
        <div
          style={{
            backgroundColor: possiblePurchase ? matiereColor(slug_mat) : "white",
          }}
          className={`h-14 max-w-screen-md max-w mt-4 px-5 mx-5 md:mx-auto flex items-center justify-center rounded-full ${
            possiblePurchase && "cursor-pointer animation duration-300 sm:hover:opacity-80"
          }`}
          onClick={() =>
            possiblePurchase &&
            setAchat({
              type: type,
              id: id,
            })
          }
        >
          <div
            style={{
              color: possiblePurchase ? "white" : matiereColor(slug_mat),
            }}
            className={`mx-auto text-center ${!possiblePurchase ? "text-base sm:text-xl font-semibold" : "text-lg sm:text-base md:text-lg"}`}
          >
            {/* {pricing === null &&
          "Les sujets de grand oral ne sont pas disponibles sous forme de pack."} */}
            {!owned && `${mobile ? "Acheter ce pack" : `Acheter le pack : « ${children} »`}`}
            {owned && `${mobile ? "Vous possedez ce pack" : `Vous possedez le pack « ${children} »`}`}
          </div>
        </div>
      )}
    </>
  );
};

const Matieres = ({ data, location }) => {
  const matieres = data;
  const currentLocation = useLocation();
  return (
    <div className="w-full pb-4">
      {matieres.map((matiere, id) => (
        <CustomLink to={`/${location}/${matiere.slug}`} slug_mat={matiere.slug} key={currentLocation.key + id}>
          {matiere.name}
        </CustomLink>
      ))}
    </div>
  );
};

const Chapitres = ({ data, possessions, location, setAchat }) => {
  const currentLocation = useLocation();
  const { slug_mat } = useParams();
  const matiereName = data.find((matiere) => matiere.slug === slug_mat).name;
  const matiereId = data.find((matiere) => matiere.slug === slug_mat).id;
  const matierePricing = data.find((matiere) => matiere.slug === slug_mat).pricing;
  const chapitres = data.find((matiere) => matiere.slug === slug_mat).chapitres;
  return (
    <React.Fragment>
      {location === "fiches" && (
        <BuyPackLink
          slug_mat={slug_mat}
          setAchat={setAchat}
          type="matiere"
          pricing={matierePricing}
          id={matiereId}
          owned={
            possessions.session === "connected" &&
            (possessions.content.matieres.find((id) => id === matiereId) !== undefined || possessions.content.offres.includes(1))
          }
        >
          {matiereName}
        </BuyPackLink>
      )}
      <div className="w-full pb-4">
        {chapitres.map((chapitre, id) => (
          <CustomLink to={`/${location}/${slug_mat}/${chapitre.slug}`} key={currentLocation.key + id} slug_mat={slug_mat}>
            {chapitre.name}
          </CustomLink>
        ))}
      </div>
    </React.Fragment>
  );
};

const ChapitreContent = ({ data, possessions, location, setAchat }) => {
  const currentLocation = useLocation();
  const { slug_mat, slug_chap } = useParams();
  const matiere = data.find((matiere) => matiere.slug === slug_mat);
  const chapitre = data.find((matiere) => matiere.slug === slug_mat).chapitres.find((chapitre) => chapitre.slug === slug_chap);

  const sorted = [];

  const notionSheets = [];
  const dissertationSheets = [];
  const explicationSheets = [];

  for (const element of Object.entries(chapitre.fiches)) {
    const fiche = element[1];
    if (fiche.type === "Fiche notions") {
      notionSheets.push(fiche);
      sorted.push(fiche.id);
    }
    if (fiche.soustype === "Dissertation") {
      dissertationSheets.push(fiche);
      sorted.push(fiche.id);
    }
    if (fiche.soustype === "Explication de texte") {
      explicationSheets.push(fiche);
      sorted.push(fiche.id);
    }
  }

  const rest = [];
  for (const element of Object.entries(chapitre.fiches)) {
    const fiche = element[1];
    if (!sorted.includes(fiche.id)) {
      rest.push(fiche);
    }
  }

  const newSheets = [...notionSheets, ...dissertationSheets, ...explicationSheets, ...rest];

  return (
    <React.Fragment>
      {location === "fiches" && (
        <BuyPackLink
          slug_mat={slug_mat}
          setAchat={setAchat}
          type="matiere"
          pricing={matiere.pricing}
          id={matiere.id}
          owned={
            possessions.session === "connected" &&
            (possessions.content.matieres.find((id) => id === matiere.id) !== undefined || possessions.content.offres.includes(1))
          }
        >
          {matiere.name}
        </BuyPackLink>
      )}
      <div className="my-2 w-full xl:max-w-screen-xl mx-auto sm:px-2">
        <div className="w-full grid auto-rows-fr grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {newSheets.map((fiche, id) => (
            <Fiche
              fiche={fiche}
              chapitreId={chapitre.id}
              matiereId={matiere.id}
              possessions={possessions}
              location={location}
              setAchat={setAchat}
              key={currentLocation.key + id}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const Fiche = ({ fiche, chapitreId, matiereId, possessions, location, setAchat }) => {
  const currentLocation = useLocation();
  const { slug_mat, slug_chap } = useParams();

  const owned =
    location === "fiches" &&
    possessions.session === "connected" &&
    ((possessions.content.offres.includes(1) && matiereId !== 7) ||
      possessions.content.chapitres.includes(chapitreId) ||
      possessions.content.matieres.includes(matiereId) ||
      possessions.content.fiches.includes(fiche.id));
  // || possessions.session !== "connected");

  const buy = !owned && location === "fiches";

  const buyOrDisplay = (event) => {
    if (buy) {
      event.preventDefault();
      setAchat({
        type: "fiche",
        id: fiche.id,
      });
    }
  };

  return (
    <Link
      key={currentLocation.key + fiche.id}
      onClick={(event) => {
        buyOrDisplay(event);
      }}
      // to={`/fiche/${slug_mat}/${slug_chap}/${fiche.slug}`}
      to={buy ? currentLocation.pathname : `/mon-espace/${slug_mat}/${slug_chap}/${fiche.slug}`}
      style={{
        minHeight: "14.5em",
      }}
      className="relative flex flex-col justify-start m-2 shadow-md rounded-lg transition duration-300 transform sm:hover:opacity-80 sm:hover:shadow-md cursor-pointer overflow-hidden"
    >
      <div
        style={{
          backgroundColor: matiereColor(slug_mat),
          minHeight: "8.3em",
        }}
        className="flex flex-col justify-start pt-5 px-5"
      >
        <h2 className="text-sm sm:text-base lg:text-lg font-semibold text-white">{fiche.type}</h2>
        <h2 className="text-sm sm:text-base text-white">{fiche.soustype}</h2>
      </div>
      <div>
        <h3 className="text-sm sm:text-base lg:text-base bg-white rounded-b-lg px-3 py-2 text-dark">{fiche.name}</h3>
      </div>
      {owned && location === "fiches" && (
        <div
          style={{
            backgroundColor: matiereColor(slug_mat),
          }}
          className="absolute bottom-3 right-3 rounded-full w-5 h-5 flex justify-center items-center text-white"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      )}
    </Link>
  );
};

const PremiumBanner = ({ setOpenOffer, hide, setHide }) => {
  const location = useLocation().pathname.split("/");
  const locationIsStructure = location?.[1] === "fiches" || location?.[1] === "mon-espace";
  const locationIsFiche = location?.[4] !== undefined;
  const hidden = hide || locationIsFiche;

  // console.log(locationIsStructure);
  // const locationIsChapitre = location?.[3] !== undefined;
  // console.log({
  //   locationIsFiche: locationIsFiche,
  //   locationIsChapitre: locationIsChapitre,
  // });
  // if (locationIsChapitre) {
  //   console.log(location?.[3]);
  //   const slug_mat = location?.[2];
  //   const slug_chap = location?.[3];
  //   const chapitre = data
  //     .find((matiere) => matiere.slug === slug_mat)
  //     .chapitres.find((chapitre) => chapitre.slug === slug_chap);
  //   console.log({
  //     chapitre: location?.[3],
  //     "nombre de fiches": chapitre.fiches.length,
  //     pair: chapitre.fiches.length % 2 ? false : true,
  //   });
  // }
  return (
    <>
      <div className="fixed bottom-0 w-full rounded-xl">
        <div className="max-w-screen-xl mx-auto flex justify-end p-4">
          <div
            className={`absolute bg-purple-500 rounded-xl w-44 h-20 sm:w-72 sm:h-40 p-2 sm:p-3 flex justify-between cursor-pointer transition ease-out duration-300 hover:bg-purple-elec transform -translate-y-full ${
              locationIsStructure ? "block" : "hidden"
            } ${hidden ? "opacity-0" : "opacity-100"}`}
            onClick={() => {
              setOpenOffer(true);
              setHide(true);
            }}
          >
            <img src={diplome} className="w-full h-auto" alt="" />
            <div className="flex flex-col justify-between">
              <div>
                <div className="text-white text-center text-sm sm:text-lg font-semibold">Accès</div>
                <div className="text-white text-center text-2xl sm:text-4xl -mt-2 font-bold font-fredoka tracking-wider">illimité</div>
              </div>
              <div className="flex justify-end items-center text-sm sm:text-lg text-white">
                Souscrire
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 sm:h-6 sm:w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getPreview = async (ficheId) => {
  const res = await axiosApi.get(
    `/sheets/preview/${ficheId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

const PreviewFiche = ({ matieres, color }) => {
  const { slug_mat, slug_chap, slug_fiche } = useParams();
  const fiche = matieres
    .find((matiere) => matiere.slug === slug_mat)
    .chapitres.find((chapitre) => chapitre.slug === slug_chap)
    .fiches.find((fiche) => fiche.slug === slug_fiche);

  const { data, isSuccess } = useQuery("sheetPreview", () => getPreview(fiche.id), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const root = document.querySelector(":root");
    const selectColor = (slug) => {
      switch (slug) {
        case "philosophie":
          return { color: "#fd6924", colorCardre: "#ffc488" };
        case "grand-oral":
          return { color: "#1aab60", colorCardre: "#93dfaa" };
        case "physique-chimie":
          return { color: "#3d75ee", colorCardre: "#d3e1ff" };
        default:
          return { color: "#1aab60", colorCardre: "#93dfaa" };
      }
    };
    const colorSheet = selectColor(slug_mat);
    root.style.setProperty("--color", colorSheet.color);
    root.style.setProperty("--cadreColor", colorSheet.colorCardre);
  }, []);

  return (
    <div>
      <div className="absolute left-0 right-0 animation duration-500 transform ease-out -z-20 opacity-100">
        <div className="pb-1">
          <div className="sheet">
            <div className="filter-preview-up" />
            <div className="filter-preview-down" />
            <div className="wrapsheet">{isSuccess && <div dangerouslySetInnerHTML={{ __html: data }}></div>}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Metadata = ({ data }) => {
  const locArray = useLocation().pathname.split("/");
  const locIsStructure = locArray[1] === "fiches" || locArray[1] === "mon-espace";
  const page = locArray[1] === "fiches" ? "Les Fiches" : "Mon Espace";

  let matiere = false;
  let chapitre = false;
  if (locArray.length > 2) {
    matiere = data.find((matiere) => matiere.slug === locArray[2]).name;
  }
  if (locArray.length > 3) {
    chapitre = data.find((matiere) => matiere.slug === locArray[2]).chapitres.find((chapitre) => chapitre.slug === locArray[3]).name;
  }

  const title = `${page} ${matiere && !chapitre ? `: ${matiere}` : ""} ${chapitre ? `: ${chapitre}` : ""} - Babac'Cool`;
  const description =
    page === "les Fiches"
      ? "Retrouve ici toutes les fiches dont tu as besoin pour réviser ton BAC."
      : "Consulte ici toutes les fiches de ton compte Babac'Cool.";

  return (
    <>
      {locIsStructure && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      )}
    </>
  );
};

const StructureFiches = ({ data, possessions, location }) => {
  const [hidePremiumBanner, setHidePremiumBanner] = useState(false);
  const currentLocation = useLocation();
  const [achat, setAchat] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openOffer, setOpenOffer] = useState(false);
  const history = useHistory();
  // const connected = possessions.session === "connected";
  const slug_mat = useLocation().pathname.split("/")?.[2];
  const { userInfos } = React.useContext(UserContext);
  const connected = userInfos.connected;
  const premium = !userInfos.offer === false;
  const { setModalProps, setOpenModal } = React.useContext(ModalContext);

  const handlePremium = ({ open }) => {
    setOpenOffer(open);
    setHidePremiumBanner(open);
  };

  const close = ({ open }) => {
    setOpen(open);
    setOpenModal(open);
  };
  // OUVERTURE DU PORTAIL POUR LA MODALE D'ACHAT
  useEffect(() => {
    if (open)
      setModalProps({
        open: open,
        setOpen: setOpen,
        color: "rgb(230, 230, 230)",
        content: <AchatModal achat={achat} setAchat={setAchat} connected={connected} setHide={close} setOpenLogin={setOpenLogin} />,
      });
    else {
      setOpenModal(false);
    }
  }, [open]);
  // OUVERTURE DU PORTAIL POUR LA MODALE D'ABONNEMENT PREMIUM
  useEffect(() => {
    if (openOffer)
      setModalProps({
        open: openOffer,
        setOpen: handlePremium,
        color: "rgb(124, 58, 237)",
        content: (
          <PremiumModal setHidePremiumBanner={setHidePremiumBanner} setOpenOffer={setOpenOffer} setOpenLogin={setOpenLogin} connected={connected} />
        ),
      });
    else {
      setOpenModal(false);
    }
  }, [openOffer]);

  useEffect(() => {
    if (achat) {
      // const slug = currentLocation.pathname.split("/").slice(2, 4);
      // const fiche = data
      //   .find((matiere) => matiere.slug === slug_mat)
      //   .chapitres.find((chapitre) => chapitre.slug === slug[1])
      //   .fiches.find((fiche) => fiche.id === achat.id);
      // console.log(slug);
      // console.log(fiche.slug);
      // const path = `/fiches/${slug.join("/")}/${fiche.slug}`;
      // console.log(path);
      // history.push(path);
      setOpen(true);
    }
  }, [achat]);

  const routes = [
    {
      path: `/${location}`,
      render: <Matieres data={data} possessions={possessions} location={location} />,
    },
    {
      path: `/${location}/:slug_mat`,
      render: <Chapitres data={data} possessions={possessions} location={location} setAchat={setAchat} />,
    },
    {
      path: `/${location}/:slug_mat/:slug_chap`,
      render: <ChapitreContent data={data} possessions={possessions} location={location} setAchat={setAchat} />,
    },
    {
      path: `/mon-espace/:slug_mat/:slug_chap/:slug_fiche`,
      render: <DisplayFiche matieres={data} color={matiereColor(slug_mat)} />,
    },
    // {
    //   path: `/fiches/:slug_mat/:slug_chap/:slug_fiche`,
    //   render: <PreviewFiche matieres={data} color={matiereColor(slug_mat)} />,
    // },
  ];
  return (
    <div>
      <Metadata data={data} />
      <NavFiches data={data} location={location} />
      <div className="max-w-screen-xl mx-auto relative">
        <TransitionGroup>
          <CSSTransition key={currentLocation.key} timeout={400} classNames={"sheetTransition"} appear>
            <Switch location={useLocation()}>
              {routes.map(({ path, render }, id) => (
                <Route key={path} exact path={path}>
                  <div className="sheetTransition">
                    {render}
                    {!premium && <div className="h-24 sm:h-44 w-full" />}
                  </div>
                </Route>
              ))}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
      {!premium && <PremiumBanner setOpenOffer={setOpenOffer} hide={hidePremiumBanner} setHide={setHidePremiumBanner} />}
      <Modal open={openLogin} setOpen={setOpenLogin}>
        <Auth setOpen={setOpenLogin} login={true} redirect={false} />
      </Modal>
      {/* <Modal open={openOffer} setOpen={handlePremium} color="rgb(124, 58, 237)">
        <PremiumModal setHidePremiumBanner={setHidePremiumBanner} setOpenOffer={setOpenOffer} setOpenLogin={setOpenLogin} connected={connected} />
      </Modal> */}
    </div>
  );
};

export default StructureFiches;

export const PremiumModal = ({ setHidePremiumBanner = () => {}, setOpenOffer, setOpenLogin, connected }) => {
  const [checkout, setCheckout] = useState(false);
  const article = { type: "offre", id: 1 };

  const setHide = ({ open }) => {
    setOpenOffer(open);
    setHidePremiumBanner(true);
  };

  return (
    <div className="absolute top-0 min-h-full right-0 left-0 flex justify-center items-center p-4 sm:p-10 md:p-16">
      <div className="hidden lg:block w-1/2 p-0 lg:pr-10">
        <img src={diplome} className="h-auto w-full" alt="" />
      </div>
      <div className="w-full sm:w-3/4 lg:w-1/2 h-full lg:pl-6">
        <div className="w-full h-full m-auto flex flex-col justify-center items-center text-white">
          {checkout ? (
            <CheckoutForm article={article} setHide={setHide} />
          ) : (
            <>
              <div className="text-3xl font-bold text-center mb-6">
                Souscrire à<br />
                l'Accès <span className="text-4xl font-fredoka tracking-wider">illimité</span>
              </div>
              <div className="text-center">
                Cette offre te donne accès à toutes les fiches du site sauf les sujets de grand oral qui s'achètent à l'unité.
                <br />
                <br />
                Cet accès n'est pas un abonnement, il n'y aura aucun renouvellement automatique à la fin des 12 mois.
              </div>
              <div className="text-2xl text-center my-5">Prix : 19.99 €</div>
              {connected ? (
                <button
                  onClick={() => {
                    setCheckout(true);
                  }}
                  className="p-3 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
                >
                  Souscrire
                </button>
              ) : (
                <button
                  onClick={() => {
                    setOpenOffer(false);
                    setHidePremiumBanner(false);
                    setOpenLogin(true);
                  }}
                  className="p-3 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
                >
                  Connecte-toi
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
