import React from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import Header from "./components/header/Header";
import IsConnected from "./components/utilities/IsConnected";
import Accueil from "./pages/Accueil";
import Contact from "./pages/Contact";
import Fiches from "./pages/Fiches/Fiches";
import MonEspace from "./pages/Fiches/MonEspace";
import { ReactQueryDevtools } from "react-query/devtools";
import Verification from "./pages/Verification";
import { Helmet } from "react-helmet";
import metaImg from "./assets/logo_bandeau-02.png";
import Conditions from "./pages/Conditions/Conditions";
import InscriptionPremium from "./pages/InscriptionPremium";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ReactGA from "react-ga";
import { CSSTransition } from "react-transition-group";
// import useViewport from "./components/utilities/useViewport";
import Modal from "./components/utilities/Modal";
import { ModalContext } from "./components/utilities/ModalContext";

// import { CheckoutForm } from "./components/utilities/StripeTest";

if (process.env.NODE_ENV !== "development") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    // debug: process.env.NODE_ENV === "development" ? true : false,
    debug: false,
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
}
// if (process.env.NODE_ENV === "development") console.log(ReactGA);

// const Error404 = () => {
//   return (
//     <div className="text-4xl text-center">
//       erreur 404 <br /> cette page n'existe pas
//     </div>
//   );
// };

const routes = [
  { path: "/", Component: Accueil },
  { path: "/contact", Component: Contact },
  { path: "/fiches", Component: Fiches },
  { path: "/mon-espace", Component: MonEspace },
  { path: "/verification", Component: Verification },
  { path: "/conditions", Component: Conditions },
  { path: "/inscription/premium", Component: InscriptionPremium },
  { path: "/mot-de-passe-oublie", Component: ForgotPassword },
  { path: "/modification-mot-de-passe", Component: ResetPassword },
  // { path: "/stripe", Component: CheckoutForm },
  // { path: "*", Component: Error404 },
];

const Metadata = () => {
  const location = useLocation().pathname.split("/")[1];
  const meta = (location) => {
    switch (location) {
      case "":
        return { title: "Babac'Cool", description: "Babac’Cool propose des fiches tout-en-un pour faciliter les révisions du BAC." };
      case "contact":
        return {
          title: "Contact - Babac'Cool",
          description: "Si tu as des questions ou des suggestions, dit le nous et on te réponds au plus vite.",
        };
      case "verification":
        return { title: "Vérification de l'adresse e-mail - Babac'Cool", description: "" };
      case "conditions":
        return {
          title: "Conditions générales - Babac'Cool",
          description:
            "Retrouve ici les conditions générales d'utilisation, les mentions légales, et les conditions générales de ventes de Babac'Cool",
        };
      case "mot-de-passe-oublie":
        return { title: "Mot de passe oublié - Babac'Cool", description: "" };
      case "modification-mot-de-passe":
        return { title: "Modification du mot de passe - Babac'Cool", description: "" };
      case "inscription":
        return { title: "Inscription compte premium - Babac'Cool", description: "" };
      default:
        return { title: "Babac'Cool", description: "Babac’Cool propose des fiches tout-en-un pour faciliter les révisions du BAC." };
    }
  };
  const { title, description } = meta(location);
  return (
    <Helmet>
      <title>{title}</title>
      {/* <meta name="title" content="Babac'cool" /> */}
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://babaccool.fr/" />
      <meta property="og:title" content="Babac'cool" />
      <meta property="og:description" content="Babac’Cool propose des fiches tout-en-un pour faciliter les révisions du BAC." />
      <meta property="og:image" content={metaImg} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://babaccool.fr/" />
      <meta property="twitter:title" content="Babac'cool" />
      <meta property="twitter:description" content="Babac’Cool propose des fiches tout-en-un pour faciliter les révisions du BAC." />
      <meta property="twitter:image" content={metaImg} />
    </Helmet>
  );
};

function App() {
  const location = useLocation();
  // const history = useHistory();
  // const [prevLocation, setPrevLocation] = useState(undefined);

  const { modalProps } = React.useContext(ModalContext);

  // let leftToRight;

  // history.listen(() => {
  //   setPrevLocation(location.pathname.split("/")?.[1]);
  // });
  // console.log(prevLocation);

  // if (prevLocation !== undefined) {
  //   let next, prev;
  //   routes.forEach((obj, index) => {
  //     if (`/${location.pathname.split("/")?.[1]}` === obj.path) next = index;
  //     if (`/${prevLocation}` === obj.path) prev = index;
  //   });
  //   leftToRight = next >= prev;
  //   // console.log({ leftToRight: leftToRight });
  // }
  // const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
  // const retina = window.devicePixelRatio > 1;
  // const optimized = false; // isChrome || (!isChrome && !retina);
  // console.log({ location: location.pathname, prevLocation: prevLocation });
  // console.log(location.pathname.split("/")[1]);
  // console.log(useHistory());

  if (process.env.NODE_ENV !== "development") ReactGA.pageview(location.pathname);
  return (
    <div className="min-h-screen">
      <Metadata />
      <IsConnected />
      <Header />
      <div>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact={path === "/" ? true : false} path={path}>
            {({ match }) => (
              <CSSTransition in={match != null} timeout={450} classNames="page" unmountOnExit mountOnEnter>
                <Component />
              </CSSTransition>
            )}
          </Route>
        ))}
      </div>
      <Modal open={modalProps.open} setOpen={modalProps.setOpen} color={modalProps.color} context={true}>
        {modalProps.content}
      </Modal>
      {/* <Switch location={location} key={location.pathname.split("/")[1]}>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/fiches">
            <Fiches />
          </Route>
          <Route path="/mon-espace">
            <MonEspace />
          </Route>
          <Route path="/verification">
            <Verification />
          </Route>
          <Route path="/conditions">
            <Conditions />
          </Route>
          <Route path="/inscription/premium">
            <InscriptionPremium />
          </Route>
          <Route path="/mot-de-passe-oublie">
            <ForgotPassword />
          </Route>
          <Route path="/modification-mot-de-passe">
            <ResetPassword />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </Switch> */}
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
}

export default App;
