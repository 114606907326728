import React, { useEffect } from "react";
import { UserContext } from "./UserContext";
import { useHistory, useLocation } from "react-router-dom";
import { axiosAuth } from "./axiosConfig";
import { axiosApi } from "./axiosConfig";
// import { useGoogleLogout } from "react-google-login";

export const deleteTokens = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken !== null) {
    axiosAuth
      .post(`/auth/logout`, { refreshToken })
      .catch((err) => console.log(err));
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  }
};

const IsConnected = () => {
  const { login, logout } = React.useContext(UserContext);
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  // const location = useLocation().pathname.split("/")?.[1];

  // const validLocation = location !== "mon-espace" && location !== "fiches";
  // const onGoogleLogoutSuccess = () => {
  //   console.log("logout");
  // };
  // const onGoogleLogoutFailure = () => {
  //   console.log("logout fail");
  // };
  // const { signOut } = useGoogleLogout({
  //   clientId: process.env.REACT_APP_GOOGLE_ID,
  //   onLogoutSuccess: onGoogleLogoutSuccess,
  //   onFailure: onGoogleLogoutFailure,
  // });

  useEffect(() => {
    if (token) {
      axiosApi
        .get(`/auth/verify`, {
          headers: {
            Authorization: localStorage.getItem("accessToken")
              ? "Bearer " + localStorage.getItem("accessToken")
              : false,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then((res) => {
          login(res.data.user.name, res.data.user.email, res.data.offer);
          // const offer = { id: 1, date: "21/15/22" };
          // setOffer(offer);
          // console.log({ userInfos: userInfos });
        })
        .catch((err) => {
          console.log(err);
          const refreshToken = localStorage.getItem("refreshToken");
          axiosApi
            .post(
              `/auth/refresh-token`,
              {
                refreshToken: refreshToken,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken")
                    ? "Bearer " + localStorage.getItem("accessToken")
                    : false,
                  "Content-Type": "application/json",
                  accept: "application/json",
                },
              }
            )
            .then((res) => {
              console.log({ refresh: res.data });
              localStorage.setItem("accessToken", res.data.accessToken);
              localStorage.setItem("refreshToken", res.data.refreshToken);
              login(res.data.user.name, res.data.user.email, res.data.offer);
            })
            .catch((err) => {
              logout();
              // signOut();
              deleteTokens();
              history.push("/");
            });
        });
    }
  }, []);

  return <></>;
};

export default IsConnected;
