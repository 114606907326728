import React, { useState, useEffect } from "react";
import logoGoogle from "../../assets/logo-google.svg";
import { axiosApi } from "../utilities/axiosConfig";
import Cookies from "js-cookie";

// import { GoogleLogin } from "react-google-login";
// import axios from "axios";

// const LoginWithGoogle = ({ setSuccess, setSocialEmailError }) => {
//   const GoogleSuccess = async (response) => {
//     console.log(response);
//     axiosApi
//       .post(`/auth/google-login`, { idToken: response.tokenId }, { withCredentials: true })
//       .then((res) => {
//         if (res.data.success === true) {
//           console.log("GOOGLE SIGNIN SUCCESS");
//           setSuccess({
//             user: {
//               name: res.data.name,
//               email: res.data.email,
//               refreshToken: res.data.refreshToken,
//               accessToken: res.data.accessToken,
//               offer: res.data.offer,
//             },
//           });
//         }
//       })
//       .catch((error) => {
//         const message = error; //.response.data.error;
//         // if (message === "email indisponible") setSocialEmailError(true);
//         console.log("GOOGLE SIGNIN ERROR", message);
//       });
//   };
//   const GoogleFailure = (error) => {
//     console.log(error);
//   };
//   return (
//     <GoogleLogin
//       clientId={process.env.REACT_APP_GOOGLE_ID}
//       render={(renderProps) => (
//         <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="focus:outline-none w-full">
//           <div className="flex justify-start items-center cursor-pointer h-12 my-2 rounded-lg transition duration-300 bg-gray-50 border border-gray-300 hover:bg-gray-200 hover:border-gray-400 focus:outline-none">
//             <img className="w-6 lg:ml-24 ml-16 mr-5" src={logoGoogle} alt={"logo de google"} />
//             <div className="font-semibold">Google</div>
//           </div>
//         </button>
//       )}
//       buttonText="Login"
//       onSuccess={GoogleSuccess}
//       onFailure={GoogleFailure}
//       cookiePolicy={"single_host_origin"}
//     />
//   );
// };

// export default LoginWithGoogle;

const LoginWithGoogle = ({ setSuccess, setSocialEmailError, loader, setLoader }) => {
  const [url, setUrl] = useState("");
  // const url =
  //   "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&client_id=1030817850552-oniau83ojtb133vj2dvfg1vqn2vpav49.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Fapi%2Fauth%2FgoogleOauth2callback%2F";
  // const url = "http://localhost:3001/api/auth/google-url";
  const [cookie, setCookie] = useState(undefined);
  const [visible, setVisible] = useState(true);

  useEffect(async () => {
    if (url === "") {
      const result = await axiosApi("/auth/google-url");
      setUrl(result.data);
    }
  }, [url]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCookie(Cookies.get("user"));
      setVisible(!document.hidden);
      // console.log({ cookie: cookie });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cookie !== undefined && visible) {
        console.log("GOOGLE SIGNIN SUCCESS");
        const user = JSON.parse(cookie);
        Cookies.remove("user");
        // console.log({ user: user });
        setSuccess({
          user: {
            name: user.name,
            email: user.email,
            refreshToken: user.refreshToken,
            accessToken: user.accessToken,
            offer: user.offer,
          },
        });
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [cookie, visible]);

  return (
    // <a href={url} target="_blank" rel="noopener noreferrer" className="block h-12 my-2 w-full">
    <div
      className="block h-12 my-2 w-full"
      onClick={() => {
        window.open(url);
        setLoader(true);
      }}
    >
      <button className="focus:outline-none w-full">
        <div className="flex justify-start items-center cursor-pointer h-12 rounded-lg transition duration-300 bg-gray-50 border border-gray-300 hover:bg-gray-200 hover:border-gray-400 focus:outline-none">
          <img className="w-6 lg:ml-24 ml-16 mr-5" src={logoGoogle} alt={"logo de google"} />
          <div className="font-semibold">Google</div>
        </div>
      </button>
    </div>
  );
};

export default LoginWithGoogle;
