import React, { useState } from "react";
import { axiosApi } from "../components/utilities/axiosConfig";
import { Link, useLocation } from "react-router-dom";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import jwt from "jwt-decode";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  border-color: red;
`;

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Veuillez entrer un mot de passe.")
    .min(8, "Minimum 8 carractères.")
    .max(100, "Maximum 100 carractères."),
});

const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const [samePassword, setSamePassword] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const token = useLocation().pathname.split("/")[2];

  let email = "";
  try {
    email = jwt(token).email;
  } catch (err) {
    console.log("no token");
  }
  console.log(email);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setSamePassword(false);
    } else {
      setApiCall(true);
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      const password = data.password;
      axiosApi
        .post(`/auth/reset-password/${token}`, { password })
        .then((res) => {
          console.log(res.data);
          setSuccess(true);
          if (res.data.success === false) {
            setError(res.data.reason);
          }
        })
        .catch((err) => {
          const error = err.response.status;
          if (error === 400) return;
        });
    }
  };
  console.log(loader);

  return (
    <div
      style={{ minHeight: "calc(100vh - 80px)" }}
      className="w-full p-0 sm:px-4 py-3 sm:py-20 xl:py-32 bg-gray-50 flex justify-center items center"
    >
      <div className="flex max-w-2xl flex-col md:justify-between justify-center mx-auto w-full h-full bg-white sm:rounded-2xl sm:shadow-2xl p-5 sm:p-10">
        <div className="text-3xl sm:text-4xl font-bold text-purple-elec text-center mb-10">
          Changement de mot de passe
        </div>
        <div className="text-lg text-center text-dark mb-8">{`Adresse e-mail : ${email}`}</div>
        {!apiCall ? (
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div>
              <div
                style={{ color: "red" }}
                className={`block -mb-2 text-sm ${errors.password && "-mt-5"}`}
              >
                {errors.password && <p>{errors.password.message}</p>}
              </div>
              <input
                type="password"
                name="password"
                ref={register}
                placeholder="Nouveau mot de passe"
                style={{ border: errors.password && "1px solid red" }}
                className="h-14 my-2 w-full pl-4 rounded-lg bg-gray-50 border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none"
              />
            </div>
            <div>
              <div style={{ color: "red" }} className={`block -mb-2 text-sm`}>
                {!samePassword && (
                  <p>Les mots de passes ne correspondent pas</p>
                )}
              </div>
              <input
                type="password"
                name="confirmPassword"
                ref={register}
                placeholder="Confirmation du mot de passe"
                style={{ border: !samePassword && "1px solid red" }}
                className="h-14 my-2 w-full pl-4 rounded-lg bg-gray-50 border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none"
                onChange={() => setSamePassword(true)}
              />
            </div>
            <input
              type="submit"
              value="ENVOYER"
              className="h-14 cursor-pointer my-2 pl-4 text-white rounded-lg transition duration-300 bg-purple-elec hover:bg-purple-900"
            />
          </form>
        ) : (
          <>
            {loader ? (
              <motion.div
                initial={{ opacity: 1, y: 0 }}
                animate={{
                  opacity: 0,
                  y: "-100px",
                  transition: { delay: 0.7, ease: "easeOut" },
                }}
                className="mx-auto my-20 w-max transition delay-150 duration-300 ease-in-out"
              >
                <SyncLoader
                  color="#6B2FC2"
                  css={override}
                  size={20}
                  margin={5}
                />
              </motion.div>
            ) : (
              <>
                {success ? (
                  <>
                    {!error ? (
                      <div className="text-xl font-semibold mt-4 mb-10 text-dark text-center">
                        Ton mot de passe à bien été modifié !
                      </div>
                    ) : (
                      <div className="text-xl font-semibold mt-4 mb-10 text-dark text-center">
                        {`Impossible de modifier le mot de passe d'un compte ${error} !`}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-xl font-semibold mt-4 mb-10 text-dark text-center">
                    Le lien que tu as utilisé n'est plus valide !
                  </div>
                )}
                <Link
                  to="/"
                  className="h-14 flex justify-center items-center cursor-pointer w-full my-2 text-white rounded-lg transition duration-300 bg-purple-elec hover:bg-purple-900"
                >
                  <p className="text-lg">ACCUEIL</p>
                </Link>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
