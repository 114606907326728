import React, { useState, useEffect } from "react";
import LoginForm from "../components/Auth/LoginForm";
import RegisterForm from "../components/Auth/RegisterForm";
import registerImg from "../assets/register2.png";
import { SuccessLogin } from "../components/Auth/LoginForm";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../components/utilities/UserContext";
import { axiosAuth } from "../components/utilities/axiosConfig";

const InscriptionPremium = () => {
  const [success, setSuccess] = useState(false);
  const [showLogin, setLogin] = useState(false);
  const [premium, setPremium] = useState(true);
  const history = useHistory();
  const hide = () => history.push("/mon-espace");

  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();
  useEffect(() => {
    return () => {
      console.log({ success: success });
      if (success !== false) {
        localStorage.setItem("refreshToken", success.user.refreshToken);
        localStorage.setItem("accessToken", success.user.accessToken);
        user.login(success.user.name, success.user.email, success.user.offer);
        axiosAuth.defaults.headers["Authorization"] = "Bearer " + success.user.accessToken;
        queryClient.invalidateQueries("possessions");
        history.push("/mon-espace");
      }
    };
  });

  return (
    <div
      className={`flex justify-center items-center fixed sm:absolute top-0 left-0 w-screen overflow-y-auto min-h-screen bg-white lg:bg-black lg:bg-opacity-30 z-30`}
    >
      <div className="w-full max-w-screen-xl xl:p-20 lg:p-5 p-0">
        <div className="flex lg:relative lg:justify-between justify-center overflow-y-auto w-full h-screen sm:h-full rounded-2xl bg-white">
          <Link to="/" className="absolute right-2 top-2 cursor-pointer">
            <svg className="w-11" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </Link>
          <div className="flex lg:w-80 md:w-80 w-72 flex-col justify-center lg:my-16 my-10 mx-24">
            <div className="text-center text-5xl font-bold text-gray-800 mb-5">{showLogin ? "Connexion" : "Inscription"}</div>
            {!showLogin && premium && <div className="text-center text-2xl uppercase font-bold text-purple-elec mb-5">Compte Premium</div>}
            {!premium ? (
              <>
                <div className="text-center text-xl font-semibold text-red-500">
                  Le lien que tu as utilisé
                  <br />
                  n'est plus valide
                </div>
                <Link to="/">
                  <button className="h-14 w-full cursor-pointer my-4 pl-4 text-white rounded-lg transition duration-300 bg-red-elec hover:bg-red-700 focus:outline-none">
                    FERMER
                  </button>
                </Link>
              </>
            ) : (
              <>
                {!success ? (
                  <>
                    {showLogin ? (
                      <LoginForm hide={hide} setSuccess={setSuccess} />
                    ) : (
                      <RegisterForm setLogin={setLogin} premium={premium} setPremium={setPremium} />
                    )}
                  </>
                ) : (
                  <SuccessLogin hide={hide} />
                )}
              </>
            )}
            <div className="text-gray-600 text-sm text-center pb-4 sm:pb-0">
              {showLogin ? "Tu n'as pas encore de compte ?" : "Tu as déjà un compte ?"}
              <div onClick={() => setLogin(!showLogin)} className="inline-block ml-1 underline cursor-pointer">
                {showLogin ? "Inscris-toi" : "Connecte-toi"}
              </div>
            </div>
          </div>
          <div
            style={{ background: "rgb(233, 239, 253)" }}
            className="lg:flex hidden justify-center items-center flex-1 h-max rounded-r-2xl bg-blue-pastel"
          >
            <img className="h-max object-cover" src={registerImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const InscriptionPremium2 = () => {
  const history = useHistory();
  const [premium, setPremium] = useState(true);
  const hide = () => history.push("/");
  return (
    <>
      <div
        style={{ WebkitTapHighlightColor: "transparent", backgroundColor: "rgb(124, 58, 237)" }}
        className="fixed top-18 sm:top-20 bottom-0 left-0 right-0 z-10 bg-purple-700 flex justify-center items-center"
      >
        <div className="relative bg-white w-11/12 h-4/5 sm:w-10/12 sm:h-4/5 md:w-8/12 md:h-3/5 lg:w-3/4 md:min-h-screen-sm lg:max-w-screen-md xl:max-w-screen-lg xl:h-3/5 shadow-2xl cursor-auto rounded-xl overflow-y-scroll">
          <Auth setPremium={setPremium} />
        </div>
      </div>
    </>
  );
};

export default InscriptionPremium2;

const Auth = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [showLogin, setLogin] = useState(false);
  const [socialEmailError, setSocialEmailError] = useState(false);
  const [loader, setLoader] = useState(false);
  const hide = () => history.push("/mon-espace");
  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const [premium, setPremium] = useState(true);
  const [open, setOpen] = useState(true);
  // useEffect(() => {
  //   return () => {
  //     console.log({ success: success });
  //     if (success !== false) {
  //       localStorage.setItem("refreshToken", success.user.refreshToken);
  //       localStorage.setItem("accessToken", success.user.accessToken);
  //       user.login(success.user.name, success.user.email, success.user.offer);
  //       axiosAuth.defaults.headers["Authorization"] = "Bearer " + success.user.accessToken;
  //       queryClient.invalidateQueries("possessions");
  //       history.push("/mon-espace");
  //     }
  //   };
  // });
  useEffect(() => {
    if (!open) {
      hide();
    }
    // return () => {
    //   cleanup
    // }
  }, [open]);
  useEffect(() => {
    if (success !== false) {
      localStorage.setItem("refreshToken", success.user.refreshToken);
      localStorage.setItem("accessToken", success.user.accessToken);
      user.login(success.user.name, success.user.email, success.user.offer);
      axiosAuth.defaults.headers["Authorization"] = "Bearer " + success.user.accessToken;
      queryClient.invalidateQueries("possessions");
    }
    return () => {
      // if (redirect && success) history.push("/mon-espace");
    };
  }, [success]);
  return (
    <div className="flex justify-center lg:justify-between h-full">
      <div className="lg:w-1/2 mx-4 my-auto">
        <div className="sm:flex flex-col justify-center max-w-80 sm:mx-auto">
          {!success && (
            <>
              <div className="text-center text-4xl h-12 font-bold text-gray-800 mt-4">{showLogin ? "Connexion" : "Inscription"}</div>
              <div className="text-center text-2xl uppercase font-bold text-purple-elec mb-5">Compte Premium</div>
              {socialEmailError && (
                <div style={{ color: "red" }} className="block -mt-1 mb-2 text-sm">
                  Cette adresse email est déjà utilisée
                </div>
              )}
            </>
          )}
          {!premium ? (
            <>
              <div className="text-center text-xl font-semibold text-red-500">
                Le lien que tu as utilisé
                <br />
                n'est plus valide
              </div>
              <Link to="/">
                <button className="h-12 w-full cursor-pointer my-4 pl-4 text-white rounded-lg transition duration-300 bg-red-elec hover:bg-red-700 focus:outline-none">
                  FERMER
                </button>
              </Link>
            </>
          ) : (
            <>
              {!success ? (
                <>
                  {showLogin ? (
                    <LoginForm hide={hide} setSuccess={setSuccess} loader={loader} setLoader={setLoader} />
                  ) : (
                    <RegisterForm setLogin={setLogin} loader={loader} premium={premium} setLoader={setLoader} setPremium={setPremium} />
                  )}
                </>
              ) : (
                <SuccessLogin hide={hide} setOpen={setOpen} />
              )}
            </>
          )}
          <div className="text-gray-600 text-sm text-center pb-4 sm:pb-0">
            {showLogin ? "Tu n'as pas encore de compte ?" : "Tu as déjà un compte ?"}
            <div
              onClick={() => {
                setLogin(!showLogin);
                setLoader(false);
              }}
              className="inline-block ml-1 underline cursor-pointer"
            >
              {showLogin ? "Inscris-toi" : "Connecte-toi"}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ background: "rgb(233, 239, 253)" }}
        className="lg:flex hidden justify-center items-center flex-1 rounded-r-2xl bg-blue-pastel w-1/2"
      >
        <img className="h-full w-full object-contain" src={registerImg} alt="" />
      </div>
    </div>
  );
};
