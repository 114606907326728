import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";
import { ModalContext } from "./ModalContext";

const Modal = ({ open, setOpen, children, color = "white", context = false }) => {
  const { setOpenModal } = React.useContext(ModalContext);
  const portal = document.getElementById("portal-root");

  // useEffect(() => {
  //   if (open) {
  //     const position = window.pageYOffset;
  //     document.body.style.position = "fixed";
  //     document.body.style.top = `-${position}px`;
  //     document.body.style.left = 0;
  //     document.body.style.right = 0;

  //     // FIXED HEADER - REMOVE IF ABSOLUTE
  //     document.getElementById("header").style.position = `absolute`;
  //     document.getElementById("header").style.top = `${position}px`;
  //   } else {
  //     const scrollY = parseInt(document.body.style.top || "0") * -1;
  //     document.body.style.position = "";
  //     document.body.style.top = "";
  //     window.scrollTo(0, scrollY);

  //     // FIXED HEADER - REMOVE IF ABSOLUTE
  //     document.getElementById("header").style.position = "";
  //     document.getElementById("header").style.top = "";
  //   }
  // }, [open]);

  const close = (context) => {
    if (context) {
      setOpenModal(false);
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  return createPortal(
    <>
      <CSSTransition in={open} timeout={400} classNames="darkerBackground" mountOnEnter unmountOnExit>
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-20" />
      </CSSTransition>
      <CSSTransition in={open} timeout={400} classNames="modal" unmountOnExit>
        <div
          style={{ WebkitTapHighlightColor: "transparent" }}
          className="fixed top-0 bottom-0 left-0 right-0 z-20 flex justify-center items-center cursor-pointer"
          onClick={() => {
            close(context);
          }}
        >
          <div
            style={{ backgroundColor: color }}
            className="relative bg-white w-11/12 h-4/5 sm:w-10/12 sm:h-4/5 md:w-8/12 md:h-3/5 lg:w-3/4 md:min-h-screen-sm lg:max-w-screen-md xl:max-w-screen-lg xl:h-3/5 shadow-md cursor-auto rounded-xl overflow-y-scroll"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                color: color === "rgb(124, 58, 237)" ? "white" : "black",
              }}
              className="absolute top-0 right-0 mt-3 mr-3 w-8 h-8 cursor-pointer z-20"
              onClick={() => setOpen(false)}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>,
    portal
  );
};

export default Modal;
