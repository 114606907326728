import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosApi } from "../utilities/axiosConfig";
import { Link, useLocation } from "react-router-dom";

// SCHEMA DE VÉRIFICATION
const schema = yup.object().shape({
  name: yup.string().required("Veuillez entrer un prénom.").min(1, "Minimum 1 carractère").max(100, "Maximum 100 carractères"),
  email: yup.string().email("Adresse e-mail invalide").required("Veuillez entrer une adresse e-mail."),
  password: yup.string().required("Veuillez entrer un mot de passe.").min(8, "Minimum 8 carractères.").max(100, "Maximum 100 carractères."),
});

// COMPOSANT
function RegisterForm({ setLogin, premium = false, setPremium = false, loader, setLoader }) {
  const [alradyExists, setAlradyExists] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [loader, setLoader] = useState(false);

  // VÉRIFICATION
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const location = useLocation();
  console.log({ premium: premium });

  // ENVOI DU FORMULAIRE
  const onSubmit = (data) => {
    setLoader(true);
    console.log(data);
    if (!premium) {
      axiosApi.post(`/auth/register`, { data }).then((res) => {
        console.log(res.data);
        if (res.data.success === false) {
          setAlradyExists(true);
          setLoader(false);
          console.log({ alradyExists: alradyExists });
        } else {
          setLoader(false);
          setSuccess({ email: res.data.email });
        }
      });
    } else {
      const key = location.pathname.split("/")[3];
      console.log(key);
      axiosApi.post(`/premium-auth/register/${key}`, { data }).then((res) => {
        console.log(res.data);
        if (res.data.success === false) {
          if (res.data?.error === "invalid key") {
            setPremium(false);
            setLoader(false);
          } else {
            setAlradyExists(true);
            setLoader(false);
          }
        } else {
          setLoader(false);
          setSuccess({ email: res.data.email });
        }
      });
    }
  };
  console.log({ alradyExists: alradyExists, success: success });

  // STYLES
  const inputStyle = "h-12 my-2 w-full pl-4 rounded-lg bg-gray-50 border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none";
  const messageStyle = (errors) => (errors ? "block mt-1 -mb-2 text-sm" : "hidden");

  // FORMULAIRE
  return (
    <div>
      {!success ? (
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div>
              <div style={{ color: "red" }} className={messageStyle(errors.name)}>
                {errors.name && errors.name.message}
              </div>
              <input
                type="text"
                name="name"
                ref={register}
                placeholder="Prénom"
                style={{ border: errors.name && "1px solid red" }}
                className={inputStyle}
                onChange={() => alradyExists && setAlradyExists(false)}
              />
            </div>
            <div>
              <div style={{ color: "red" }} className={messageStyle(errors.email)}>
                {alradyExists && "Email indisponible"}
                {errors.email && errors.email.message}
              </div>
              {alradyExists && (
                <div style={{ color: "red" }} className="block mt-1 -mb-2 text-sm">
                  Email indisponible
                </div>
              )}
              <input
                type="text"
                name="email"
                ref={register}
                placeholder="Adresse e-mail"
                style={{ border: errors.email && "1px solid red" }}
                className={inputStyle}
                onChange={() => setAlradyExists(false)}
              />
            </div>
            <div>
              <div style={{ color: "red" }} className={messageStyle(errors.password)}>
                {errors.password && errors.password.message}
              </div>
              <input
                type="password"
                name="password"
                ref={register}
                placeholder="Mot de passe"
                style={{ border: errors.password && "1px solid red" }}
                className={inputStyle}
                onChange={() => setAlradyExists(false)}
              />
            </div>
            <div
              className={`relative h-12 w-full mt-2 mb-1 pl-4 text-white rounded-lg transition duration-300 flex justify-center items-center ${
                loader ? "bg-green-500" : "bg-purple-elec hover:bg-purple-900"
              }`}
            >
              <input
                type="submit"
                value=""
                className={`absolute z-10 w-full h-full bg-transparent ${loader ? "cursor-default" : "cursor-pointer"}`}
              />
              {loader ? (
                <>
                  <p>CHARGEMENT</p>
                  <div className="spinner"></div>
                </>
              ) : (
                <p>INSCRIPTION</p>
              )}
            </div>
          </form>
          <p className="text-xs text-gray-400 text-center my-3 w-60 mx-auto">
            En cliquant sur "inscription", tu acceptes les{" "}
            <Link to="/conditions" target="_blank" rel="noopener noreferrer" className="underline cursor-pointer">
              conditions générales d'utilisation et les mentions légales
            </Link>
            {" de babaccool"}
          </p>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="text-xl font-semibold mt-5 mb-3 text-gray-800 text-center">{`Nous avons envoyé un e-mail à ${success.email},`}</div>
          <div className="text-base text-dark mb-5 text-center">pour finaliser ton inscription, rends-toi dans ta boite mail !</div>
          <button
            className="h-12 text-center focus:outline-none cursor-pointer w-full my-2 pl-4 text-white rounded-lg transition duration-300 bg-purple-elec hover:bg-purple-900"
            onClick={() => setLogin(true)}
          >
            ME CONNECTER
          </button>
        </div>
      )}
    </div>
  );
}

export default RegisterForm;
