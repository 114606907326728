import React, { useState, useEffect } from "react";
import useViewport from "../components/utilities/useViewport";
import { UserContext } from "../components/utilities/UserContext";
import { axiosAuth } from "../components/utilities/axiosConfig";
import { Link } from "react-router-dom";
import logo from "../assets/logo_bandeau-02.png";
import point1 from "../assets/point-1.svg";
import point2 from "../assets/point-2.svg";
import point3 from "../assets/point-3.svg";
import method from "../assets/method-min.png";
import Footer from "../components/footer/Footer";
import { PremiumModal } from "../pages/Fiches/StructureFiches";
import Auth from "../components/Auth/Auth";
import Modal from "../components/utilities/Modal";

const KeyPoint = ({ title, img, children }) => {
  return (
    <div className="p-3 shadow-md md:shadow-xl rounded-lg border border-gray-200">
      {/* <div className="p-3"> */}
      <div>
        <img className="w-4/12 m-2 mx-auto object-contain h-max-content" src={img} alt="" />
        <h2 className="text-dark text-xl font-bold text-center my-3">{title}</h2>
        <h3 className="text-sm md:text-base text-center text-gray-600 mb-3">{children}</h3>
      </div>
    </div>
  );
};

const Pricing = ({ title, price, children, inverse = false, grandOral = false, setOpen = () => {} }) => {
  const { userInfos } = React.useContext(UserContext);
  const premium = !userInfos.offer === false;
  const { width } = useViewport();
  return (
    <div
      className={`flex flex-col items-center p-5 rounded-lg shadow-md md:shadow-xl ${
        inverse ? "bg-purple-elec" : "border border-purple-elec bg-white"
      }`}
    >
      <div className={`text-4xl h-20 md:h-32 lg:h-20 pt-5 font-bold text-center ${inverse ? "text-white" : "text-dark"}`}>{title}</div>
      {children}
      <div className={`text-4xl font-bold ${inverse ? "text-white" : "text-dark"}`}>{`${price} €`}</div>
      <div className="my-10">
        <Link to={`${grandOral ? "/fiches/grand-oral" : "/fiches"}`}>
          <div
            className={`flex items-center rounded animation duration-300 transform hover:scale-x-105 uppercase text-xl py-3 text-center ${
              inverse ? "bg-white text-purple-elec" : "bg-purple-elec text-white"
            } ${width < 1100 && width > 770 ? "h-20 px-4" : "px-8"}`}
            onClick={(e) => {
              if (inverse && !premium) {
                e.preventDefault();
                setOpen(true);
              }
            }}
          >
            <div>{inverse && !premium ? "Souscrire" : "Voir les fiches"}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const Accueil = () => {
  const token = localStorage.getItem("accessToken");
  const { connected } = React.useContext(UserContext).userInfos;
  const [available, setAvailable] = useState(false);

  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    if (connected || token) {
      axiosAuth.get("/sheets/offer-disponible").then((res) => {
        if (res !== undefined) {
          setAvailable(res?.data?.disponible === true);
        }
      });
    } else {
      setAvailable(true);
    }
  }, [connected, token]);

  const { width } = useViewport();
  return (
    <div className="w-screen bg-gray-50">
      {/* <div className="w-full bg-gradient-to-r from-purple-elec to-purple-pastel py-5"> */}
      <div className="w-full bg-gradient-to-r from-purple-800 to-purple-400 py-5">
        <div className="flex flex-col md:flex-row justify-between items-center w-full max-w-screen-xl mx-auto">
          <div className="px-6 w-full md:w-6/12">
            <div className="text-3xl sm:text-5xl text-white font-bold my-10">Révise simplement et rapidement le bac !</div>
            <h1 className="text-base sm:text-lg text-white">
              Retrouve tout le programme de terminale sous forme de <span className="font-bold">sujets corrigés</span> et fiches notions synthétiques
              & des sujets de <span className="font-bold">grand oral </span>
              de plein de spécialités différentes entièrement rédigés sur la page des fiches.
            </h1>
            {available && (
              <div className="mt-10 w-full flex-col items-center justify-center">
                <Link to="/fiches">
                  <div className="block bg-white text-purple-elec text-base sm:text-lg uppercase rounded py-3 px-6 text-center w-max mx-auto animation duration-300 transform hover:scale-105">
                    {width < 500 ? "Première fiche gratuite !" : "Obtenir ma première fiche gratuitement"}
                  </div>
                </Link>
                <div className="text-center text-white text-xs sm:text-sm mb-10 mt-3">* Offre non valable pour les fiches du grand oral</div>
              </div>
            )}
          </div>
          <img className="w-8/12 md:w-4/12 my-5 block" src={logo} alt="Logo de du site" />
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-flow-row gap-5 grid-cols-1 md:grid-cols-3 my-5 md:my-10 p-3">
          <KeyPoint title="Fiches notions synthétiques" img={point1} alt="">
            Les notions essentielles de chaque chapitre définies et expliquées simplement et brièvement.
          </KeyPoint>
          <KeyPoint title="Sujets corrigés" img={point2} alt="">
            Sujets corrigés entièrement rédigés et détaillés pour apprendre le cours à partir d’exercices concrets.
          </KeyPoint>
          <KeyPoint title="Méthode demandée aux examens" img={point3} alt="">
            Chaque sujet corrigé fait apparaitre explicitement la méthode demandée au BAC afin de la comprendre et de l’assimiler rapidement.
          </KeyPoint>
        </div>
        <div className="flex flex-col md:flex-row items-center py-5 md:py-10">
          <img className="w-8/12 md:w-6/12" src={method} alt="" />
          <div className="p-5 10/12 md:w-6/12 flex flex-col justify-center">
            <div className="text-3xl md:text-5xl text-dark font-bold leading-normal">
              Découvre la methode
              <span className="font-fredoka uppercase tracking-widest"> Babac’Cool </span>!
            </div>
            <h4 className="text-gray-600 mt-5">
              Babac’Cool propose des fiches tout-en-un pour faciliter les révisions du BAC. Nous avons regroupé un ensemble de sujets corrigés qui
              permet de revoir l’ensemble du programme pour chaque chapitre de chaque matière. L’idée est de réviser le cours en même temps que la
              méthode avec des sujets corrigés rédigés et complets, qui font apparaitre la méthode explicitement.
            </h4>
          </div>
        </div>
        <div className="grid auto-rows-fr gap-2 grid-cols-1 md:grid-cols-4 my-5 p-3 pt-3 pb-60 md:mt-10 mb-20 sm:pb-40">
          <Pricing title={`Fiche à l'unité`} price="1,99">
            <div className="text-lg h-30 md:h-40 lg:h-28 font-semibold text-center py-10 text-dark">
              Une fiche notion ou un sujet corrigé au choix
            </div>
            <div className="text-center text-gray-500 text-sm h-14 md:h-24 lg:h-14 flex items-center"></div>
          </Pricing>
          <Pricing title="Accès illimité" price="19,99" inverse={true} setOpen={setOpen}>
            <div className="text-lg h-30 md:h-40 lg:h-28 font-semibold text-center py-10 text-white">
              Accès total à l'ensemble des fiches disponibles sur le site
            </div>
            <div className="text-center text-white text-sm h-14 md:h-24 lg:h-14 flex items-center">
              <div>* Les fiches du grand oral ne sont disponibles qu'à l'unité</div>
            </div>
          </Pricing>
          <Pricing title="Fiche grand oral" price="4,99" grandOral={true}>
            <div className="text-lg h-30 md:h-40 lg:h-28 font-semibold text-center py-10 text-dark">Un sujet corrigé du grand oral au choix</div>
            <div className="text-center text-gray-300 text-sm h-14 md:h-24 lg:h-14 flex items-center"></div>
          </Pricing>
          <Pricing title="Pack matière" price="9,99">
            <div className="text-lg h-30 md:h-40 lg:h-28 font-semibold text-center py-10 text-dark">
              Toutes les fiches de tous les chapitres d'une matière
            </div>
            <div className="text-center text-gray-500 text-sm h-14 md:h-24 lg:h-14 flex items-center">
              <div>* Les fiches du grand oral ne sont disponibles qu'à l'unité</div>
            </div>
          </Pricing>
        </div>
      </div>
      <Footer />
      <Modal open={openLogin} setOpen={setOpenLogin}>
        <Auth setOpen={setOpenLogin} login={true} redirect={false} />
      </Modal>
      <Modal open={open} setOpen={setOpen} color="rgb(124, 58, 237)">
        <PremiumModal setOpenOffer={setOpen} setOpenLogin={setOpenLogin} connected={connected} />
      </Modal>
    </div>
  );
};

export default Accueil;
