import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import registerImg from "../../assets/register2.png";
import LoginGoogle from "./LoginGoogle";
import LoginFacebook from "./LoginFacebook";
import { SuccessLogin } from "./LoginForm";
import { useQueryClient } from "react-query";
import { UserContext } from "../utilities/UserContext";
import { axiosAuth } from "../utilities/axiosConfig";
import ReactGA from "react-ga";

// const Register = ({ hide, login, redirect }) => {
//   const [success, setSuccess] = useState(false);
//   const [showLogin, setLogin] = useState(login ? true : false);
//   const [socialEmailError, setSocialEmailError] = useState(false);

//   const user = React.useContext(UserContext);
//   const history = useHistory();
//   const queryClient = useQueryClient();
//   useEffect(() => {
//     return () => {
//       console.log({ success: success });
//       if (success !== false) {
//         localStorage.setItem("refreshToken", success.user.refreshToken);
//         localStorage.setItem("accessToken", success.user.accessToken);
//         console.log("userAuth:" + success);
//         user.login(success.user.name, success.user.email, success.user.offer);
//         axiosAuth.defaults.headers["Authorization"] = "Bearer " + success.user.accessToken;
//         queryClient.invalidateQueries("possessions");
//         if (process.env.NODE_ENV !== "development")
//           ReactGA.event({
//             category: "Utilisateur",
//             action: "Connexion",
//           });
//         if (redirect) history.push("/mon-espace");
//       }
//     };
//   });

//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     return () => {
//       document.body.style.overflow = "unset";
//     };
//   }, []);

//   return (
//     <div
//       className={`flex justify-center items-center fixed sm:absolute top-0 left-0 w-screen overflow-y-auto min-h-screen bg-white lg:bg-black lg:bg-opacity-30 z-30`}
//       onClick={hide}
//     >
//       <div className="w-full max-w-screen-xl xl:p-20 lg:p-5 p-0">
//         <div
//           className="flex lg:relative lg:justify-between justify-center overflow-y-auto w-full h-screen sm:h-full rounded-2xl bg-white"
//           onClick={(e) => e.stopPropagation()}
//         >
//           <div className="absolute right-2 top-2 cursor-pointer" onClick={hide}>
//             <svg className="w-11" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//             </svg>
//           </div>
//           <div className="sm:flex lg:w-80 md:w-80 w-72 flex-col justify-center lg:my-16 my-10 mx-2 sm:mx-24">
//             <div className="text-center text-5xl font-bold text-gray-800 mb-7">{showLogin ? "Connexion" : "Inscription"}</div>
//             <LoginFacebook setSuccess={setSuccess} setSocialEmailError={setSocialEmailError} />
//             <LoginGoogle setSuccess={setSuccess} setSocialEmailError={setSocialEmailError} />
//             {socialEmailError && (
//               <div style={{ color: "red" }} className="block -mt-1 mb-2 text-sm">
//                 Cette adresse email est déjà utilisée
//               </div>
//             )}
//             <div className="flex justify-center items-center w-64 h-px mx-auto bg-gray-300 my-2">
//               <span className="px-4 bg-white text-gray-600">OU</span>
//             </div>
//             {!success ? (
//               <>{showLogin ? <LoginForm hide={hide} setSuccess={setSuccess} /> : <RegisterForm setLogin={setLogin} />}</>
//             ) : (
//               <SuccessLogin hide={hide} />
//             )}
//             <div className="text-gray-600 text-sm text-center pb-4 sm:pb-0">
//               {showLogin ? "Tu n'as pas encore de compte ?" : "Tu as déjà un compte ?"}
//               <div onClick={() => setLogin(!showLogin)} className="inline-block ml-1 underline cursor-pointer">
//                 {showLogin ? "Inscris-toi" : "Connecte-toi"}
//               </div>
//             </div>
//           </div>
//           <div
//             style={{ background: "rgb(233, 239, 253)" }}
//             className="lg:flex hidden justify-center items-center flex-1 rounded-r-2xl bg-blue-pastel"
//           >
//             <img className="object-cover" src={registerImg} alt="" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Register;

const Auth = ({ setOpen, login = false, redirect = false }) => {
  const [success, setSuccess] = useState(false);
  const [showLogin, setLogin] = useState(login ? true : false);
  const [socialEmailError, setSocialEmailError] = useState(false);
  const [loader, setLoader] = useState(false);

  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    // if (redirect && success) history.push("/mon-espace");
    if (success !== false) {
      localStorage.setItem("refreshToken", success.user.refreshToken);
      localStorage.setItem("accessToken", success.user.accessToken);
      // console.log("userAuth:" + success);
      user.login(success.user.name, success.user.email, success.user.offer);
      axiosAuth.defaults.headers["Authorization"] = "Bearer " + success.user.accessToken;
      queryClient.invalidateQueries("possessions");
      if (process.env.NODE_ENV !== "development")
        ReactGA.event({
          category: "Utilisateur",
          action: "Connexion",
        });
    }
  }, [success]);
  return (
    <div className="flex justify-center lg:justify-between h-full">
      <div className="lg:w-1/2 mx-4 my-auto">
        <div className="sm:flex flex-col justify-center max-w-80 sm:mx-auto">
          {!success && (
            <>
              <div className="text-center text-4xl h-12 font-bold text-gray-800 mt-4 mb-4">{showLogin ? "Connexion" : "Inscription"}</div>
              <LoginFacebook setSuccess={setSuccess} setSocialEmailError={setSocialEmailError} loader={loader} setLoader={setLoader} />
              <LoginGoogle setSuccess={setSuccess} setSocialEmailError={setSocialEmailError} loader={loader} setLoader={setLoader} />
              {socialEmailError && (
                <div style={{ color: "red" }} className="block -mt-1 mb-2 text-sm">
                  Cette adresse email est déjà utilisée
                </div>
              )}
              <div className="flex justify-center items-center w-64 h-px mx-auto bg-gray-300 my-4">
                <span className="px-4 bg-white text-gray-600">OU</span>
              </div>
            </>
          )}
          {!success ? (
            <>
              {showLogin ? (
                <LoginForm hide={() => setOpen(false)} setSuccess={setSuccess} loader={loader} setLoader={setLoader} />
              ) : (
                <RegisterForm setLogin={setLogin} loader={loader} setLoader={setLoader} />
              )}
            </>
          ) : (
            <SuccessLogin setOpen={setOpen} redirect={redirect} />
          )}
          <div className="text-gray-600 text-sm text-center pb-4 sm:pb-0">
            {showLogin ? "Tu n'as pas encore de compte ?" : "Tu as déjà un compte ?"}
            <div
              onClick={() => {
                setLogin(!showLogin);
                setLoader(false);
              }}
              className="inline-block ml-1 underline cursor-pointer"
            >
              {showLogin ? "Inscris-toi" : "Connecte-toi"}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ background: "rgb(233, 239, 253)" }}
        className="lg:flex hidden justify-center items-center flex-1 rounded-r-2xl bg-blue-pastel w-1/2"
      >
        <img className="h-full w-full object-contain" src={registerImg} alt="" />
      </div>
    </div>
  );
};

export default Auth;
