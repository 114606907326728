import React, { useState, useEffect } from "react";
import { axiosApi } from "../../components/utilities/axiosConfig";
import { useQuery } from "react-query";
import StructureFiches from "./StructureFiches";
import { UserContext } from "../../components/utilities/UserContext";
import { axiosAuth } from "../../components/utilities/axiosConfig";
import { useHistory } from "react-router";

const getData = async () => {
  const res = await axiosApi.get(
    `/sheets/structure`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
    {
      withCredentials: true,
    }
  );
  return res.data;
};

const getPossessions = async (connected) => {
  let res = false;
  // console.log(connected);
  if (connected) {
    res = await axiosAuth.get(`/sheets/possessions`);
    // console.log(res?.data.content);
  } else {
    res = {
      data: {
        session: false,
      },
    };
  }
  return res.data;
};

const Fiches = () => {
  const [loader, setLoader] = useState(false);
  const { userInfos } = React.useContext(UserContext);
  const connected = userInfos.connected;
  const { data, status, isSuccess } = useQuery("sheetsStructure", getData, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const possessions = useQuery(["possessions", connected], () => getPossessions(connected), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const history = useHistory();
  history.listen(() => setLoader(false));

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isSuccess) {
        setLoader(false);
        document.body.style.overflow = "";
      } else {
        setLoader(true);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);

  return (
    <div className="w-full z-10">
      <div className="flex justify-center items-center w-full bg-gradient-to-r from-purple-pastel to-blue-pastel">
        <div className="text-2xl font-normal tracking-widest lg:text-3xl font-fredoka text-white text-center px-8 py-4">LES FICHES</div>
      </div>
      {loader && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center">
          <div className="mx-auto w-max transition delay-150 duration-300">
            <div className="lds-ellipsis">
              <div style={{ backgroundColor: "#C8A7FF" }} />
              <div style={{ backgroundColor: "#C8A7FF" }} />
              <div style={{ backgroundColor: "#C8A7FF" }} />
              <div style={{ backgroundColor: "#C8A7FF" }} />
            </div>
          </div>
        </div>
      )}
      {status === "success" && possessions?.status === "success" && !loader && (
        <StructureFiches data={data} location="fiches" possessions={possessions.data} />
      )}
    </div>
  );
};

const Fiches2 = () => {
  return <div className="h-screen w-screen bg-gradient-to-tr from-purple-900 to-pink-300 "></div>;
};
export default Fiches;
