import React from "react";

const ConditionsGeneralesVentes = () => {
  return (
    <>
      <h1 className="title">Conditions Générales de Vente</h1>
      <div className="title2">1. Objet</div>
      <p>
        Les présentes Conditions Générales de Vente (ci-après « les CGV ») ont
        pour objet de définir les droits et obligations des parties résultant de
        la vente en ligne de prestations (ci-après « les Prestations »)
        proposées à la vente par la société Babac’Cool (ci-après la « Société »)
        au client (ci-après le « Client »), sur le site Internet babaccool.fr
        (ci-après « le Site ») et ses déclinaisons, notamment les applications
        pour tablettes et mobiles (ci-après l'« Application mobile ») .
      </p>
      <p>
        Les Prestations désignent tous les produits et services proposés à la
        vente par la Société sur le Site et sur l'Application mobile.
      </p>
      <p>
        Les présentes CGV entrent en vigueur à leur date de publication sur le
        Site et/ou sur l'Application mobile. La validation de la commande
        entraîne l'acceptation sans restriction ni réserve, par le Client, des
        présentes CGV, à l'exclusion de tout autre document tel que prospectus
        ou catalogue émis par la Société, et vaut conclusion d'un contrat
        (ci-après le « Contrat ») avec la Société.
      </p>
      <p>
        Aucune condition particulière ne peut, sauf acceptation formelle et
        écrite de la Société, prévaloir sur les présentes CGV, qui constituent
        le Contrat passé entre le Client et la Société. Les mineurs sont admis à
        créer un compte personnel sur le Site ou sur l'Application mobile, à
        condition, pour les mineurs de moins de 15 ans, de recueillir
        l'autorisation préalable d'au moins un titulaire de l'autorité parentale
        pour (i) la collecte de leurs données à caractère personnel et (ii)
        l’achat de fiches. Il convient de préciser que le titulaire de
        l'autorité parentale du mineur inscrit sera responsable du respect par
        le mineur des CGU ainsi que des présentes CGV.
      </p>
      <p>
        Le Client de plus de 18 ans a la possibilité de créer un compte, et de
        procéder à l’achat de fiches pour le compte d'un utilisateur de moins de
        18 ans, dont il devra préciser l'identité au moment de la création dudit
        compte.
      </p>
      <div className="title2">2. L’achat de fiches</div>
      <div className="title3">2.1. Description</div>
      <p>
        La Société propose à la vente des fiches de révisions, exercices ou
        sujets corrigés, des packs chapitres ou matières comprenant plusieurs
        fiches.
      </p>
      <p>
        La création d’un compte est obligatoire pour acheter des fiches, des
        packs chapitres ou des packs matières.
      </p>
      <p>
        Une fois le paiement réalisé, l’utilisateur a accès à l’intégralité des
        contenus en illimité sur son téléphone, ordinateur ou tablette. Ces
        contenus ne sont pas téléchargeables.
      </p>
      <p>
        La Société se réserve le droit d'apporter aux contenus toutes les
        modifications, suppressions et améliorations de son choix, liées
        notamment à l'évolution technique, dans les limites autorisées par la
        loi.
      </p>
      <p>
        Par ailleurs, la Société se réserve le droit, sans préavis ni indemnité,
        de suspendre temporairement l'accès aux contenus pour des raisons
        légales ou réglementaires, ou pour assurer des opérations de
        maintenance, de réparation, d'entretien ou de mise à jour, liées à
        l'évolution technologique, ou nécessaires à la continuité de ces
        Prestations.
      </p>
      <p>
        Le Client dégage la Société de toute responsabilité à cet égard et
        renonce à toute réclamation et/ou procédure à son encontre de ce fait.
        Les interruptions temporaires du service seront, dans la mesure du
        possible, notifiées via le Site et/ou l'Application mobile au minimum 24
        (vingt-quatre) heures avant qu'elles n'interviennent, sauf lorsque ces
        interruptions ont un caractère d'urgence.
      </p>
      <p>
        Pour bénéficier de ces mises à jour sur son appareil mobile (téléphone
        et/ou tablette numérique compatibles), le Client devra connecter son
        application mobile à Internet et télécharger lesdites mises à jour.
      </p>
      <div className="title3">2.2. Résiliation</div>
      <p>
        Le Client a la faculté de résilier son compte à tout moment et cette
        résiliation sera effective dans un délai de 24 heures
      </p>
      <p>
        L'accès de façon illimitée au contenu des documents cessera à la date
        d'effet de la résiliation.
      </p>
      <div className="title2">3. Commande de fiches</div>
      <p>
        Afin de réaliser toute commande de fiches sur le Site et/ou sur
        l'Application mobile, le Client devra obligatoirement suivre les étapes
        suivantes :
      </p>
      <ul>
        <li>
          Suivre les instructions du Site et/ou de l'Application mobile et en
          particulier, les instructions nécessaires à l'ouverture d'un compte
          client ;
        </li>
        <li>
          Remplir le formulaire de commande et déclarer avoir pris connaissance
          des CGV en cochant la case correspondante ;
        </li>
        <li>Valider la commande ;</li>
        <li>
          Suivre les instructions du serveur de paiement en ligne sécurisé afin
          de procéder au paiement.
        </li>
      </ul>
      <p>
        Le Client recevra ensuite, par courrier électronique et sans délai, i)
        une confirmation de paiement, ainsi que ii) un accusé de réception
        valant confirmation de commande et iii) un lien vers les présentes CGV.
      </p>
      <p>
        Conformément à l'article L. 121-11 du Code de la consommation, la
        Société se réserve le droit de refuser la commande si elle est anormale,
        passée de mauvaise foi ou pour tout autre motif légitime, et en
        particulier, lorsqu'il existe un litige avec le Client concernant le
        paiement d'une commande antérieure.
      </p>
      <div className="title2">4. Prix - Conditions de paiement</div>
      <p>
        Les prix des fiches sont ceux en vigueur sur le Site et/ou sur
        l'Application mobile au jour de la validation de la commande. Les prix
        peuvent évoluer mais les modifications tarifaires ne s'appliqueront pas
        aux documents déjà acquis.
      </p>
      <p>
        Au moment de la validation de la commande, le prix à payer s'entend du
        prix Toutes Taxes Comprises (TTC) et non Hors Taxes (HT).
      </p>
      <p>
        Le prix à payer comprend les frais de livraison éventuels, conformément
        à la commande. En revanche, les frais de télécommunication inhérents à
        l'accès au Site et à l'Application mobile restent à la charge exclusive
        du Client.
      </p>
      <p>
        Le paiement du prix par le Client s'effectue à l'aide des moyens
        suivants sur le Site et/ou sur l'Application mobile : cartes bancaires
        (Carte Bleue, Visa, Eurocard / MasterCard), PayPal. Lorsque le Client
        communique à la Société ses coordonnées bancaires, celles-ci sont
        susceptibles d'être conservées par la Société, afin de permettre le
        paiement d'éventuels achats ultérieurs sur le Site et/ou sur
        l'Application mobile.
      </p>
      <p>
        Le Client peut demander à la Société de cesser de conserver ses
        coordonnées bancaires dans les conditions prévues à l'Article 8 des
        présentes CGV.
      </p>
      <p>
        Les coordonnées bancaires du Client pourront également être conservées
        par un prestataire de services de paiement sécurisés en ligne,
        indépendant de la Société, dans les conditions précisées par celui-ci.
      </p>
      <p>
        Le paiement des fiches fait l'objet d'un règlement intégral lors de la
        commande. La transaction est immédiatement débitée sur la carte bancaire
        du Client après vérification des données de celle-ci et réception de
        l'autorisation de débit de la part de la société émettrice de la carte
        bancaire utilisée par le Client.
      </p>
      <p>
        Conformément à l'article L. 133-8 du Code monétaire et financier,
        l'engagement de payer donné au moyen d'une carte de paiement est
        irrévocable. En communiquant les informations relatives à sa carte
        bancaire, le Client autorise la Société à débiter sa carte bancaire du
        montant correspondant au prix de la Prestation au jour de la commande.
      </p>
      <p>
        La Société met en œuvre tous les moyens pour assurer la confidentialité
        et la sécurité des données transmises sur le Site et sur l'Application
        mobile par le Client.
      </p>
      <div className="title2">5. Droit de rétractation</div>
      <p>
        Concernant les fiches et conformément aux dispositions de l'article L.
        221-28 du Code de la consommation, le Client est informé que le droit de
        rétractation dont il bénéficie ne peut être exercé pour les contenus
        numériques non fournis sur un support matériel et dont l'exécution a
        commencé après accord préalable exprès du consommateur et renoncement
        exprès à son droit de rétractation.
      </p>
      <p>
        Le Client reconnaît et accepte expressément i) que la fourniture des
        fiches commence dès la confirmation de la commande et donc avant la fin
        du délai de rétractation de 14 (quatorze) jours prévus par le Code de la
        consommation et ii) qu'il renonce expressément à son droit de
        rétractation. En conséquence, aucune demande de rétractation,
        d'annulation ou de remboursement ne sera recevable.
      </p>
      <div className="title2">6. Garantie - Responsabilité</div>
      <p>
        La Société est soumise aux conditions de garanties légales prévues aux
        articles L. 217- 4, L. 217-5, L. 217-12, et L. 211-1 du Code de la
        consommation.
      </p>
      <p>
        Les documents livrés par la Société et objet de la commande doit être
        utilisée selon les indications qui figurent sur le Site et/ou sur
        l'Application mobile.
      </p>
      <p>
        Le Client déclare être informé des contraintes et des limites des
        réseaux Internet et Internet mobile. En conséquence, la Société ne
        pourra en aucun cas être tenue responsable de dysfonctionnements dans
        l'accès aux Prestations, des vitesses d'ouverture et de consultation des
        pages, de l'inaccessibilité temporaire ou définitive aux Prestations ou
        de l'utilisation frauduleuse par des tiers des informations mises à
        disposition sur le Site et/ou sur l'Application mobile.
      </p>
      <p>
        En conséquence également, il incombe au Client de protéger son matériel
        informatique ou autre notamment contre toute forme d'intrusion et/ou de
        contamination par des virus. La Société ne saurait en aucun cas être
        tenue pour responsable de ce fait. La Société ne saurait être tenue
        responsable de tout dysfonctionnement ou de toute détérioration du
        matériel du Client.
      </p>
      <p>
        En aucun cas la Société ne saurait être tenue responsable dans
        l'hypothèse où les Prestations proposées s'avéreraient incompatibles
        avec certains équipements et/ou fonctionnalités du matériel informatique
        du Client.
      </p>
      <p>
        Le Client est seul responsable de l'utilisation qu'il fait des
        Prestations et ne saurait tenir responsable la Société pour toute
        réclamation et/ou procédure à son encontre de ce fait. Le Client
        s'engage par ailleurs à faire son affaire personnelle de toute
        réclamation, revendication, ou opposition et plus généralement de toute
        procédure formée contre la Société émanant d'un tiers qui serait liée à
        son utilisation des Prestations.
      </p>
      <p>
        Le mot de passe permettant de se connecter à un compte personnel est
        strictement personnel, et il incombe au Client d'en préserver la
        sécurité et la confidentialité. Le Client est responsable de toute
        utilisation de son mot de passe sur le Site et/ou sur l'Application
        mobile, et la Société ne saurait être tenue responsable des éventuelles
        pertes que le Client pourrait subir résultant d'une utilisation
        frauduleuse de son mot de passe.
      </p>
      <div className="title2">7. Propriété intellectuelle</div>
      <p>
        Le Site et l'Application mobile dans leur ensemble, et chacun des
        éléments qui les composent (tels que notamment textes, cours, exercices,
        énoncés, corrigés, méthodes, logiciels, animations, images, dessins et
        modèles, marques, photographies, illustrations, schémas, logos, et
        l'ensemble des éléments téléchargeables, etc.) sont, sauf mentions
        particulières, la propriété exclusive de la Société qui est seule
        habilitée à utiliser les droits de propriété intellectuelle y afférents.
      </p>
      <p>
        En conséquence, la reproduction, la copie, la diffusion, la cession, la
        représentation et l'altération de tout ou partie du Site et/ou de
        l'Application mobile sur tout support, pour un usage autre que personnel
        et privé dans un but non commercial sont strictement interdits, sauf
        autorisation expresse et préalable de la Société.
      </p>
      <p>
        La violation de ces dispositions est passible de sanctions conformément
        aux dispositions des Codes de la Propriété Intellectuelle et Pénal, au
        titre notamment de la contrefaçon de droit d'auteur et de droit des
        marques, ainsi que du Code Civil en matière de responsabilité civile.
      </p>
      <p>
        Si toutefois, le contenu du Site et/ou de l'Application mobile devait
        porter atteinte aux droits de propriété intellectuelle de tiers, la
        Société s'engage à retirer aussitôt de sa publication, après avoir été
        informée de l'atteinte, l'objet du litige et/ou fera connaître le
        véritable auteur ou propriétaire des droits.
      </p>
      <div className="title2">8. Confidentialité des données</div>
      <p>
        Lors de l'ouverture d'un compte personnel sur le Site ou sur
        l'Application mobile, la Société est susceptible de collecter vos
        données personnelles. Lorsque vous fournissez des données personnelles,
        vous vous engagez à communiquer des informations exactes et ne portant
        pas préjudice aux intérêts et/ou aux droits des tiers.
      </p>
      <p>
        Les données personnelles collectées peuvent concerner des mineurs. Ces
        données sont strictement nécessaires à la fourniture des Prestations et
        ne seront traitées par la Société qu'avec le consentement préalable des
        parents recueilli par le biais d'une case à cocher sur le formulaire de
        collecte pour les mineurs de moins de 15 ans.
      </p>
      <p>
        Vous vous engagez à informer la Société sans délai de toute modification
        des informations fournies lors de la commande des Prestations et
        notamment tout changement d'adresse de courrier électronique ou de
        moyens de paiement. Vous pouvez modifier ces informations à partir de
        votre compte personnel sur le Site ou sur l'Application mobile.
      </p>
      <p>
        Les informations demandées au Client sont strictement nécessaires au
        traitement de la commande.
      </p>
      <p>
        Les informations et données recueillies concernant le Client font
        l'objet d'un traitement informatique opéré par la Société qui a pour
        finalité (i) de vous fournir un service personnalisé, (ii) de gérer vos
        demandes, (iii) de produire des analyses statistiques, (iv) de vous
        proposer, le cas échéant, de la publicité et du contenu marketing
        personnalisés. La fourniture de ces informations et données est
        nécessaire pour vous offrir les services auxquels vous souhaitez avoir
        accès. A défaut de renseignement de vos données personnelles, votre
        demande d’achat de fiches ne pourra pas être traitée.
      </p>
      <p>
        Dans le cadre des finalités décrites ci-dessus, vos données peuvent être
        transmises à des sociétés prestataires chargées notamment de la gestion,
        de l'exécution et du traitement des opérations de paiement. Ces données
        sont traitées et stockées chez l'hébergeur du Site et/ou de
        l'Application mobile identifié au sein des mentions légales figurant sur
        le Site ou sur l'Application mobile dans des conditions visant à assurer
        leur sécurité.
      </p>
      <p>
        Lorsque vous créez un compte, vos données sont stockées pendant une
        durée de 3 ans.
      </p>
      <p>
        La Société s'engage à prendre toutes les mesures de sécurité techniques
        et organisationnelles nécessaires pour préserver la confidentialité et
        la sécurité de vos données personnelles.
      </p>
      <p>
        Les données sont traitées par la Société en conformité avec les
        dispositions de la loi n° 78-17 du 6 janvier 1978 dite informatique et
        libertés.
      </p>
      <p>
        Si vous créez un compte personnel sur le Site ou sur l'Application
        mobile, vous êtes susceptible d'être destinataire de contenus
        informatifs, commerciaux ou promotionnels de la part de la Société
        (notamment la lettre d'information (« newsletter ») de la Société,
        offres promotionnelles, tutos, etc...) à l'adresse de courrier
        électronique renseignée. Si vous ne souhaitez plus recevoir ces
        contenus, vous pouvez à tout moment le signaler à la Société en cliquant
        sur le lien prévu à cet effet en bas de chaque contenu.
      </p>
      <p>
        Conformément à la Loi Informatique et Libertés n°78-17 du 6 janvier 1978
        modifiée et ses décrets d'application, vous disposez d'un droit d'accès,
        de rectification, d’effacement, d’opposition, à la limitation du
        traitement et à la portabilité des données qui vous concernent. Vous
        pouvez exercer vos droits selon les modalités décrites ci-dessous à tout
        moment en vous adressant à notre support client à l'adresse
        <a href="mailto:contact.client@babaccool.fr">
          {" "}
          contact.client@babaccool.fr
        </a>
      </p>
      <div className="title2">9. Absence de renonciation tacite</div>
      <p>
        Le fait pour l'une des parties de ne pas exercer, à un moment
        quelconque, une prérogative qui lui est reconnue par les présentes CGV,
        ou de ne pas exiger l'exécution par l'autre partie d'une stipulation
        quelconque desdites conditions ne pourra en aucun cas être interprété
        comme une renonciation expresse ou tacite de cette partie à son droit
        d'exercer ladite prérogative dans l'avenir, ou à son droit d'exiger
        l'exécution scrupuleuse des engagements souscrits par l'autre partie.
      </p>
      <div className="title2">10. Force majeure</div>
      <p>
        L'exécution par la Société de ses obligations aux termes des présentes
        CGV sera suspendue en cas de survenance d'un cas fortuit ou de force
        majeure qui en gênerait ou en retarderait l'exécution.
      </p>
      <p>
        La Société avisera le Client de la survenance d'un tel cas fortuit ou de
        force majeure dans les 7 (sept) jours à compter de la date de survenance
        de l'événement.
      </p>
      <p>
        Lorsque la suspension de l'exécution des obligations de la Société se
        poursuit pendant une période supérieure à 30 (trente) jours, le Client a
        la possibilité de résilier la commande en cours et la Société procédera
        alors au remboursement de la commande dans un délai de 30 (trente) jours
        à compter de la date de résiliation de la commande.
      </p>
      <p>
        La Société remboursera la somme versée directement sur la carte bancaire
        utilisée par le Client pour le paiement.
      </p>
      <div className="title2">11. Nullité d'une clause</div>
      <p>
        Chacune des dispositions des présentes CGV est indépendante. Si l'une
        quelconque des dispositions des présentes CGV était annulée, cette
        nullité n'entraînerait pas la nullité des autres dispositions qui
        demeureront en vigueur entre les parties.
      </p>
      <div className="title2">12. Cession</div>
      <p>
        Le Client ne peut en aucun cas céder ou transmettre à un tiers, à titre
        onéreux ou gratuit, sous quelque forme que ce soit, le Contrat, en
        totalité ou en partie, sans accord préalable et écrit de la Société.
      </p>
      <p>
        La Société se réserve le droit de céder le Contrat et l'un quelconque de
        ses droits ou obligations, en totalité ou en partie, à un tiers.
      </p>
      <div className="title2">13. Modifications des CGV</div>
      <p>La Société se réserve le droit de modifier les présentes CGV.</p>
      <p>
        Les modifications des CGV seront applicables à toute nouvelle commande
        sur le Site et/ou sur l'Application mobile.
      </p>
      <p>
        Les modifications éventuelles n'ont pas d'incidence sur les achats
        passés qui restent soumis aux CGV applicables au moment de la commande.
      </p>
      <div className="title2">
        14. Loi applicable et attribution de juridiction
      </div>
      <p>
        Les présentes CGV sont soumises à la loi française. Tout litige
        résultant de la formation, de l'interprétation ou de l'exécution des
        présentes CGV sera de la compétence exclusive des juridictions de Paris
        (France), sans préjudice de votre droit, si vous êtes un consommateur,
        d'engager une procédure devant les tribunaux compétents d'une autre
        ville, conformément aux lois définissant les droits des consommateurs.
      </p>
      <p>
        Vous avez également la possibilité de saisir gratuitement au médiateur
        de la consommation dont les coordonnées figurent ci-dessous :
      </p>
      <div className="infos">
        MCP Médiation
        <br />
        12 Square Desnouettes
        <br />
        75015 Paris
        <br />
        <a href="mailto:contact@mcpmediation.org">contact@mcpmediation.org</a>
      </div>
    </>
  );
};

export default ConditionsGeneralesVentes;
