import React from "react";
import Footer from "../../components/footer/Footer";
import ConditionsGenerales from "./ConditionsGenerales";
import ConditionsGeneralesVentes from "./ConditionsGeneralesVentes";
import MentionsLegales from "./MentionsLegales";
import "./conditionsStyles.css";
import { Switch, Route, Redirect, useLocation, Link } from "react-router-dom";

const NavLink = ({ to, children, location }) => {
  const active = to === location;
  return (
    <Link
      to={`/conditions/${to}`}
      className={`mx-3 w-max uppercase text-sm font-semibold px-1 pb-1 pt-3 border-b-2 ${
        active
          ? "border-purple-elec text-purple-elec cursor-auto"
          : "text-dark transition duration-300 border-white hover:border-purple-elec hover:text-purple-elec"
      }`}
    >
      {children}
    </Link>
  );
};

const Conditions = () => {
  const location = useLocation().pathname.split("/")[2];
  return (
    <>
      <nav className="flex flex-col mx-auto max-w-screen-lg px-2 lg:px-24 md:flex-row flex-wrap justify-center lg:justify-between items-center mt-10">
        <NavLink to="generales" location={location}>
          Conditions Générales d'Utilisation
        </NavLink>
        <NavLink to="mentions-legales" location={location}>
          Mentions Légales
        </NavLink>
        <NavLink to="ventes" location={location}>
          Conditions Générales de Ventes
        </NavLink>
      </nav>
      <div className="conditionsWrapper">
        <Switch>
          <Route exact path="/conditions">
            <Redirect to="/conditions/generales" />
          </Route>
          <Route path="/conditions/generales">
            <ConditionsGenerales />
          </Route>
          <Route path="/conditions/mentions-legales">
            <MentionsLegales />
          </Route>
          <Route path="/conditions/ventes">
            <ConditionsGeneralesVentes />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default Conditions;
