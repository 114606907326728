import axios from "axios";

const apiUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

export const axiosAuth = axios.create({
  baseURL: apiUrl,
  timeout: 5000,
  headers: {
    Authorization: localStorage.getItem("accessToken") ? "Bearer " + localStorage.getItem("accessToken") : false,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const axiosApi = axios.create({
  baseURL: apiUrl,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (error.response.status === 403 && originalRequest.url === "auth/refresh-token") {
      return Promise.reject(error);
    }
    console.log({ status: error.response.status, url: originalRequest.url });

    if (error.response.data.session === "need a refresh" && error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        return axiosAuth
          .post("auth/refresh-token", { refreshToken: refreshToken })
          .then((response) => {
            if (response.data.session === "expired") {
              window.location.href = "/";
            }
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);

            axiosAuth.defaults.headers["Authorization"] = "Bearer " + response.data.accessToken;
            originalRequest.headers["Authorization"] = "Bearer " + response.data.accessToken;
            console.log("interceptor works");

            return axiosAuth(originalRequest);
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      } else {
        console.log("Refresh token not available.");
        window.location.href = "/";
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
