import React from "react";

const ConditionsGenerales = () => {
  return (
    <>
      <h1 className="title">Conditions Générales d'Utilisation</h1>
      <div className="title2">1. Champ d'application</div>
      <p>
        L'accès au site Internet babaccool.fr (ci-après « le Site »), ainsi que
        l'utilisation de son contenu, s'effectue dans le cadre des présentes
        Conditions Générales d'Utilisation. Le fait d'accéder et de naviguer sur
        le Site constitue de votre part une acceptation sans réserve des
        présentes Conditions Générales d'Utilisation.
      </p>
      <div className="title2">
        2. Nature et portée des informations publiées sur le site
      </div>
      <p>
        2.1. Le Site propose un accès à des fiches notions, exercices corrigés,
        et autres types de documents développés par la société Babaccool
        (ci-après « la Société »).
      </p>
      <p>
        2.2. Le Site propose également un accès à des opinions ou à des
        témoignages émis par des utilisateurs de babaccool.fr. De telles
        informations n'engagent que les personnes consultées et ne traduisent
        pas nécessairement l'opinion de la Société.
      </p>
      <p>
        2.3. L'ensemble de ces informations est publié sur le Site à titre
        d'information uniquement et pour aider à la compréhension des programmes
        scolaires. Les informations ne sauraient en aucun cas constituer un
        substitut aux programmes scolaires officiels.
      </p>
      <p>
        2.4. En conséquence, vous reconnaissez que la responsabilité de la
        Société ne pourra être recherchée au titre de l'information proposée sur
        le Site et vous acceptez que l'utilisation de ces informations
        s'effectue sous votre seule et entière responsabilité, contrôle et
        direction.
      </p>
      <div className="title2">3. Garanties et responsabilités</div>
      <p>
        3.1. Le contenu du Site a été élaboré avec le plus grand soin avec des
        professionnels et a fait l'objet d'un contrôle minutieux de la part de
        la Société qui s'efforce d'assurer au mieux que les informations
        accessibles sur le Site sont exactes et régulièrement mises à jour.
      </p>
      <p>
        Toutefois, la Société n'assure aucune garantie expresse ou tacite
        concernant tout ou partie du Site et n'est pas responsable de
        l'actualité, l'exactitude, l'exhaustivité ni de la qualité des
        informations diffusées sur le Site.
      </p>
      <p>
        3.2. En aucun cas la Société ne saurait être tenue pour responsable d'un
        quelconque dommage matériel ou immatériel, direct ou indirect, quelle
        qu'en soit la cause, l'origine, la nature ou les conséquences, découlant
        de la consultation et/ou de l'utilisation du Site.
      </p>
      <p>
        3.3. En outre, la Société décline toute responsabilité en cas
        d'interruption ou d'inaccessibilité du Site, de survenance de bogues ou
        de tout dommage résultant d'actes frauduleux (tels que des intrusions)
        de tiers à partir du Site.
      </p>
      <p>
        3.4. La Société met en œuvre des moyens destinés à assurer la
        confidentialité et la sécurité des informations à caractère personnel
        collectées sur le Site. Toutefois, la société ne maitrise pas les
        risques liés au fonctionnement d'Internet et attire votre attention sur
        l'existence de risques éventuels en termes de confidentialité des
        données transitant via ce réseau.
      </p>
      <div className="title2">4. Liens hypertexte</div>
      <p>
        4.1. Les pages de ce Site peuvent contenir des liens hypertexte
        renvoyant vers d'autres sites Internet exploités par des tiers. Les
        liens hypertexte vers les sites Internet de tiers sont destinés à
        faciliter la navigation des internautes. La Société n'exerce aucun
        contrôle actuel ou futur sur la réalisation, le contenu, la mise à jour,
        la véracité ou l'exactitude des sites externes accessibles par
        hyperliens, ni sur leurs offres, leurs annonces publicitaires et les
        liens auxquels ils renvoient. La Société ne saurait par conséquent être
        tenue pour responsable des contenus illégaux, erronés ou incomplets
        présents sur les sites Internet de tiers et particulièrement pour des
        dommages résultant de l'utilisation des informations mises à disposition
        sur ces sites.
      </p>
      <p>
        4.2. La Société n'assume également aucune responsabilité relative à une
        quelconque violation de droits d'auteur, marques ou autres droits de
        propriété intellectuelle pouvant résulter de l'accès à un site Internet
        de tiers par un lien hypertexte.
      </p>
      <p>
        4.3. La création de liens hypertexte vers le Site, autre que vers sa
        page d'accueil, ne peut être faite qu'avec l'autorisation préalable,
        écrite et expresse de la Société. Cette autorisation peut être révoquée
        à tout moment et sans indemnité. En aucun cas, les pages du Site ne
        doivent se retrouver imbriquées à l'intérieur des pages d'un autre site
        Internet. Dans tous les cas, un message doit indiquer au visiteur que
        celui-ci change de site Internet.
      </p>
      <div className="title2">5. Propriété intellectuelle</div>
      <p>
        5.1. La Société est titulaire du nom de domaine babaccool.fr (et de ses
        déclinaisons). Le Site et le concept qui y sont associés sont des œuvres
        de l'esprit protégées par le droit de la propriété intellectuelle.
      </p>
      <p>
        5.2. Le Site dans son ensemble et chacun des éléments qui le composent
        (tels que notamment fiches, cours, exercices, énoncés, corrigés, images,
        dessins, marques, photographies, illustrations, schémas, logos... et
        l'ensemble des éléments consultables », etc.) sont, sauf mentions
        particulières, la propriété exclusive de la Société qui est seule
        habilitée à utiliser les droits de propriété intellectuelle y afférents.
      </p>
      <p>
        5.3. En conséquence, la reproduction, la représentation et l'altération
        de tout ou partie du Site sur tout support, pour un usage autre que
        personnel et privé dans un but non commercial sont strictement
        interdites, sauf autorisation expresse et préalable de la Société.
      </p>
      <p>
        5.4. La violation de ces dispositions est passible de sanctions
        conformément aux dispositions des Codes de la Propriété Intellectuelle
        et Pénal, au titre notamment de la contrefaçon de droit d'auteur et de
        droit des marques, ainsi que du Code Civil en matière de responsabilité
        civile.
      </p>
      <p>
        5.5. Si toutefois, le contenu du Site devait porter atteinte aux droits
        de propriété intellectuelle de tiers, la Société s'engage à retirer
        aussitôt de sa publication, après avoir été informée de l'atteinte,
        l'objet du litige et/ou fera connaître le véritable auteur ou
        propriétaire des droits.
      </p>
      <div className="title2">6. Données personnelles</div>
      <p>
        6.1. Pour consulter et visiter le Site, vous n'avez pas à communiquer de
        données personnelles.
      </p>
      <p>
        6.2. Pour accéder à l'ensemble des contenus proposés par la Société,
        vous devez obligatoirement créer un compte personnel sur le Site.
      </p>
      <p>
        6.3. La Société est susceptible de collecter vos données personnelles
        lorsque vous créez un compte personnel sur le Site. En particulier, si
        vous choisissez d'insérer à votre compte personnel votre photographie,
        vous acceptez expressément que la Société stocke cette photographie. La
        Société est également susceptible de collecter vos données personnelles
        lorsque vous la sollicitez par l'intermédiaire de la rubrique « contact
        » afin de répondre à toute question que vous pourriez avoir.
      </p>
      <p>
        6.4. Les données personnelles collectées peuvent concerner des mineurs.
        Ces données sont strictement nécessaires à la fourniture du service et
        ne seront traitées par la Société qu'avec le consentement préalable des
        parents.
      </p>
      <p>
        6.5. Lorsque vous fournissez des données personnelles, vous vous engagez
        à communiquer des informations exactes et ne portant pas préjudice aux
        intérêts et/ou aux droits des tiers.
      </p>
      <p>
        6.6. Les informations et données recueillies vous concernant font
        l'objet d'un traitement informatique opéré par la Société qui a pour
        finalité (i) de vous fournir un service personnalisé, (ii) de gérer vos
        demandes, (iii) de produire des analyses statistiques, (iv) de vous
        proposer, le cas échéant, de la publicité et du contenu marketing
        personnalisés. La fourniture de ces informations et données est
        nécessaire pour vous offrir les services auxquels vous souhaitez avoir
        accès. A défaut de renseignement de vos données personnelles, votre
        demande de création de compte ne pourra pas être traitée.
      </p>
      <p>
        Dans le cadre des finalités ci-dessus, vos données peuvent être
        transmises à des sociétés prestataires chargées notamment de la gestion,
        de l'exécution et du traitement des opérations de paiement. Ces données
        sont traitées et stockées chez l'hébergeur du Site identifié au sein des
        mentions légales figurant sur le Site dans des conditions visant à
        assurer leur sécurité.
      </p>
      <p>
        6.7. La Société s'engage à prendre toutes les mesures de sécurité
        techniques et organisationnelles nécessaires pour préserver la
        confidentialité et la sécurité de vos données personnelles.
      </p>
      <p>
        6.8. Si vous créez un compte personnel sur le Site, vous êtes
        susceptible d'être destinataire de contenus informatifs, commerciaux ou
        promotionnels de la part de la Société à l'adresse de courrier
        électronique renseignée. Si vous ne souhaitez plus recevoir ces
        contenus, vous pouvez à tout moment le signaler à la Société en cliquant
        sur le lien prévu à cet effet en bas de chaque contenu.
      </p>
      <p>
        6.9. Conformément à la Loi Informatique et Libertés n°78-17 du 6 janvier
        1978 modifiée et ses décrets d'application, vous disposez d'un droit
        d'accès, de rectification, d’effacement, d’opposition, à la limitation
        du traitement et à la portabilité des données qui vous concernent. Vous
        pouvez exercer vos droits à tout moment en vous adressant à notre
        support client à l'adresse{" "}
        <a href="mailto:contact.client@babaccool.fr">
          contact.client@babaccool.fr
        </a>
        .
      </p>
      <div className="title2">7. Cookies</div>
      <p>
        7.1. La Société est susceptible d'implanter des cookies dans votre
        terminal.
      </p>
      <p>
        7.2. Un cookie est un petit fichier texte déposé sur votre disque dur et
        dont le but est de collecter des informations sur la manière dont vous
        visitez le Site. Les cookies aident également à améliorer la qualité du
        Site et donc à fournir à l'internaute une expérience qualitative et
        agréable de visite du Site.
      </p>
      <p>
        7.3. Les cookies que la Société peut être amenée à installer dans votre
        terminal sont destinés à faciliter et à rendre plus agréable votre
        expérience sur le Site. Il s'agit de cookies d'identification de session
        et de cookies analytiques.
      </p>
      <div className="title2">8. Compte personnel et mot de passe</div>
      <p>
        8.1. Si vous choisissez ou si la Société vous transmet un code
        d'identification, un mot de passe ou toute autre information vous
        permettant de vous connecter à votre compte personnel, vous devez
        considérer ces informations comme confidentielles. Vous ne devez pas les
        divulguer à un tiers.
      </p>
      <p>
        8.2. La Société se réserve à tout moment le droit de suspendre tout code
        d'identification ou mot de passe, si vous n'avez pas respecté les
        dispositions ci-dessus énoncées.
      </p>
      <p>
        8.3. Si vous connaissez ou suspectez un tiers ayant connaissance de
        votre code d'identification ou mot de passe, vous devez en informer la
        Société à notre support client à l'adresse{" "}
        <a href="mailto:contact.client@babaccool.fr">
          contact.client@babaccool.fr
        </a>
        .
      </p>
      <div className="title2">
        9. Modification des présentes conditions générales d'utilisation
      </div>
      <p>
        9.1. La Société se réserve expressément le droit de modifier les
        présentes Conditions Générales d'Utilisation, à l'exception des clauses
        relatives à la durée, aux caractéristiques et au prix des services
        proposés par la Société, à tout moment et sans préavis.
      </p>
      <p>
        9.2. Ces modifications sont publiées par leur mise en ligne et sont
        réputées acceptées sans réserve lorsque vous accédez au Site
        postérieurement à leur mise en ligne.
      </p>
      <p>
        9.3. La Société se réserve également le droit de modifier, de compléter
        ou de supprimer tout ou partie du contenu du Site ou de l'offre qu'elle
        y présente sans préavis, temporairement ou définitivement.
      </p>
      <p>
        9.4. Nous vous recommandons par conséquent de consulter régulièrement la
        présente rubrique.
      </p>
      <div className="title2">10. Litiges</div>
      <p>
        10.1. Les présentes Conditions Générales d'Utilisation sont établies en
        conformité avec le droit français, notamment les dispositions de la Loi
        n°2004-575 du 21 juin 2004 « pour la confiance dans l'économie numérique
        », ainsi que la Loi n°78-17 du 6 janvier 1978 « relative à
        l'informatique, aux fichiers et aux libertés » ainsi que les
        modifications issues du Règlement européen sur la protection des données
        (RGPD). En cas de contestation relative à l’usage de vos données, vous
        pouvez saisir la Commission Nationale de l’Informatique et des Libertés.
      </p>
      <p>
        10.2. Les juridictions françaises sont territorialement compétentes pour
        connaître de tout litige afférent au Site, sans préjudice de votre
        droit, si vous êtes un consommateur, d'engager une procédure devant les
        tribunaux compétents d'une autre ville, conformément aux lois
        définissant les droits des consommateurs. Vous avez également la
        possibilité de saisir gratuitement au médiateur de la consommation dont
        les coordonnées figurent ci-dessous :
      </p>
      <div className="infos">
        MCP Médiation
        <br />
        12 Square Desnouettes
        <br />
        75015 Paris
        <br />
        01 40 61 03 33
        <br />
        <a href="mailto:contact@mcpmediation.org">contact@mcpmediation.org</a>
      </div>
    </>
  );
};

export default ConditionsGenerales;
