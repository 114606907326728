import React, { useState } from "react";

export const ModalContext = React.createContext({
  open: false,
  setOpen: false,
  color: false,
  content: "",
});

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    open: false,
    setOpen: false,
    color: false,
    content: "",
  });

  const setOpenModal = (open) => {
    if (!open) {
      setModalProps((prevState) => ({
        ...prevState,
        open: false,
      }));
      // setModalProps({
      //   open: false,
      //   setOpen: false,
      //   color: false,
      //   content: "",
      // });
    }
  };

  return <ModalContext.Provider value={{ modalProps, setModalProps, setOpenModal }}>{children}</ModalContext.Provider>;
};
