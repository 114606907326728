import React, { useState, useEffect } from "react";
import { axiosAuth } from "../../components/utilities/axiosConfig";
import { useQuery } from "react-query";
import "./fiche_style_2.css";
import { useParams, useHistory } from "react-router-dom";
import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";
// import { animateScroll as scroll } from "react-scroll";

const getFiche = async (ficheId) => {
  const res = await axiosAuth.get(`/sheets/${ficheId}`);
  return res.data;
};

const Loader = ({ loader, display, color }) => {
  const [close, setClose] = useState(false);
  const history = useHistory();
  history.listen(() => setClose(true));

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!loader) setClose(true);
  //   }, 300);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [loader]);
  const portal = document.getElementById("portal-root");
  return createPortal(
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-0 transition duration-300 ${
        loader ? "opacity-100" : "opacity-0"
      } ${close || !display ? "visible" : "invisible"}`}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-transparent -z-10"></div>
      <div className="lds-ellipsis">
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
        <div style={{ backgroundColor: color }} />
      </div>
    </div>,
    portal
  );
};

const Metadata = ({ fiche }) => {
  const history = useHistory();
  const [display, setDisplay] = useState(true);
  history.listen(() => {
    setDisplay(false);
  });
  const title = `${fiche.type === "Fiche notions" ? `Fiche notions : ${fiche.name}` : `Fiche : ${fiche.name}`} - Babac'Cool`;
  const description =
    fiche.type === "Fiche notions"
      ? `Retrouve ici la fiche contenant toutes les notions essentielles du chapitre « ${fiche.name} »`
      : `Retrouve ici le contenu de la fiche « ${fiche.name} »`;
  return (
    <>
      {display && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
      )}
    </>
  );
};

const DisplayFiche = ({ matieres, color }) => {
  const { slug_mat, slug_chap, slug_fiche } = useParams();
  const [loader, setLoader] = useState(false);
  const [display, setDisplay] = useState(false);
  const history = useHistory();

  const fiche = matieres
    .find((matiere) => matiere.slug === slug_mat)
    .chapitres.find((chapitre) => chapitre.slug === slug_chap)
    .fiches.find((fiche) => fiche.slug === slug_fiche);

  const ficheId = fiche.id;

  const { data, isSuccess } = useQuery("sheetData", () => getFiche(ficheId), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  history.listen(() => {
    setLoader(false);
  });
  useEffect(() => {
    let timer0 = setTimeout(() => {
      setLoader(true);
    }, 50);
    return () => clearTimeout(timer0);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isSuccess) {
        setLoader(false);
      }
    }, 300);
    let timer1 = setTimeout(() => {
      if (isSuccess) {
        setDisplay(true);
        document.body.style.overflow = "";
      }
    }, 600);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer1);
    };
  }, [isSuccess]);
  // console.log({ success: isSuccess });
  // console.log({ loader: loader });

  // const fiche = data;
  // let html;
  // let images = [];

  // if (isSuccess && fiche.html !== null) {
  //   if (isSuccess) {
  //     html = new Buffer.from(fiche.html).toString("utf-8");

  //     if (fiche.images !== null) {
  //       //   fiche.images.forEach((img) => {
  //       //     images.push(new Buffer.from(img).toString("base64"));
  //       //   });
  //       //   console.log({ images: images });
  //       //   images.forEach((image, index) => {
  //       //     html = html.replaceAll(
  //       //       `"image${index + 1}"`,
  //       //       `data:image/png;base64,${image}`
  //       //     );
  //       //   });
  //       // console.log(fiche.images);
  //     }
  //   }
  // }
  let html;
  if (isSuccess) html = data;

  useEffect(() => {
    const root = document.querySelector(":root");
    const selectColor = (slug) => {
      switch (slug) {
        case "philosophie":
          return { color: "#fd6924", colorCardre: "#ffc488" };
        case "grand-oral":
          return { color: "#1aab60", colorCardre: "#93dfaa" };
        case "physique-chimie":
          return { color: "#3d75ee", colorCardre: "#d3e1ff" };
        case "hggsp":
          return { color: "#9c52fc", colorCardre: "#ceb9ff" };
        case "sciences-economiques-et-sociales":
          return { color: "rgb(255, 131, 228)", colorCardre: "rgb(255, 219, 247)" };
        default:
          return { color: "#1aab60", colorCardre: "#93dfaa" };
      }
    };
    const colorSheet = selectColor(slug_mat);
    root.style.setProperty("--color", colorSheet.color);
    root.style.setProperty("--cadreColor", colorSheet.colorCardre);
  }, []);

  return (
    <div>
      <Metadata fiche={fiche} />
      <Loader loader={loader} display={display} color={color} />
      <div className={`absolute left-0 right-0 animation duration-500 transform ease-out -z-20 ${display ? "opacity-100" : "opacity-0"}`}>
        <div className="pb-1">
          <div className="sheet">
            <div className="filter"></div>
            <div className="wrapsheet" dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
        {/* <MathJax.Provider
          url="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"
          options={{
            loader: { load: ["[tex]/mhchem"] },
            tex: {
              inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"],
              ],
              packages: { "[+]": ["mhchem"] },
            },
          }}
        >
          <div className="pb-1">
            <div className="sheet">
              <div className="filter"></div>
              <div className="wrapsheet">
                <MathJax.Html html={html} />
              </div>
            </div>
          </div>
        </MathJax.Provider> */}
      </div>
    </div>
  );
};

export default DisplayFiche;
