import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import achatImg from "../../assets/achat1.png";
// import ShowAuth from "../../components/Auth/ShowAuth";
import CheckoutForm from "../../components/utilities/CheckoutForm";
import { axiosAuth } from "../../components/utilities/axiosConfig";
import { Link } from "react-router-dom";
import useViewport from "../../components/utilities/useViewport";
import { createPortal } from "react-dom";

const articleInfos = (type, id, data) => {
  let name;
  let pricing;
  let typeFiche;
  let sousTypeFiche;
  data.forEach((matiere) => {
    if (type === "matiere" && matiere.id === id) {
      name = matiere.name;
      pricing = matiere.pricing;
    }
    matiere.chapitres.forEach((chapitre) => {
      if (type === "chapitre" && chapitre.id === id) {
        name = chapitre.name;
        pricing = chapitre.pricing;
      }
      chapitre.fiches.forEach((fiche) => {
        if (type === "fiche" && fiche.id === id) {
          name = fiche.name;
          pricing = fiche.pricing;
          typeFiche = fiche.type;
          sousTypeFiche = fiche.soustype;
          // console.log(fiche);
        }
      });
    });
  });
  if (pricing === "fiche normale")
    return {
      type: type,
      id: id,
      name: name,
      pricing: pricing,
      typeFiche: typeFiche,
      sousTypeFiche: sousTypeFiche,
    };
  return { type: type, id: id, name: name, pricing: pricing };
};

const typeArticle = (type) => {
  switch (type) {
    case "matiere":
      return "Pack matiere :";
    case "chapitre":
      return "Pack chapitre :";
    case "fiche":
      return "Fiche :";
    default:
      break;
  }
};

const price = (pricing) => {
  switch (pricing) {
    case "matiere":
      return "9.99 €";
    case "chapitre":
      return "7.99 €";
    case "fiche normale":
      return "1.99 €";
    case "fiche grand oral":
      return "4.99 €";
    default:
      break;
  }
};

const offerDisponible = (pricing, setDisponible) => {
  if (pricing !== "fiche normale") {
    return;
  }
  axiosAuth.get("/sheets/offer-disponible").then((res) => {
    if (res !== undefined) {
      return setDisponible(res.data.disponible);
    }
  });
};
const freeSheet = (id, setSuccess) => {
  axiosAuth.get(`/sheets/free-sheet/${id}`).then((res) => {
    // console.log({ freeSheet: res });
    if (res.data.insered === true) {
      return setSuccess(true);
    } else return;
  });
};

const descriptionProduit = (article) => {
  if (article.pricing === "fiche grand oral")
    return `Retrouve dans cette fiche le sujet de grand oral « ${article.name} » entièrement détaillé et rédigé, avec les éléments de méthode apparents pour te permettre de bien la comprendre et l’assimiler, ainsi que des conseils pour le jour J, des questions possibles de la part du jury et des suggestions de réponses possibles à ces questions!`;
  if (article.pricing === "chapitre")
    return `En achetant le pack chapitre tu auras accès, dans ton espace de révisions à la fiche des notions essentielles du chapitre « ${article.name} » et à plusieurs sujets corrigés en lien avec ce chapitre.`;
  if (article.pricing === "matiere")
    return `En achetant le pack matière, tu auras accès, dans ton espace de révisions à l’ensemble des fiches notions des différents chapitres de « ${article.name} » et à des sujets corrigés permettant de revoir l’ensemble du programme de la matière.`;
  if (article.pricing === "fiche normale") {
    if (article.typeFiche === "Fiche notions")
      return `Retrouve dans cette fiche les notions essentielles du chapitre sur « ${article.name} » avec les distinctions à avoir en tête et les repères étymologiques.`;
    else {
      switch (article.sousTypeFiche) {
        case "Explication de texte":
          return `Retrouve dans cette fiche le commentaire détaillé et intégralement rédigé de « ${article.name} », avec les éléments de méthode apparents pour te permettre de bien la comprendre et l’assimiler.`;
        case "Dissertation":
          return `Retrouve dans cette fiche le corrigé détaillé et intégralement rédigé du sujet de dissertation « ${article.name} », avec les éléments de méthode apparents pour te permettre de bien la comprendre et l’assimiler.`;
        case "Exercice corrigé":
          return `Retrouve dans cette fiche le sujet type bac « ${article.name} », corrigé et intégralement rédigé avec les éléments de méthode apparents pour te permettre de bien le comprendre et l’assimiler.`;
        default:
          return;
      }
    }
  }
};

const ArticleDescription = ({ article, connected, setCheckout, setHide, setOpenLogin }) => {
  const [success, setSuccess] = useState(false);
  const [disponible, setDisponible] = useState(false);
  if (connected) {
    offerDisponible(article.pricing, setDisponible);
  }
  return (
    <>
      {success ? (
        <div className="w-full lg:w-8/12 mx-auto my-auto z-10">
          <div className={`font-fredoka text-purple-elec tracking-wider text-bold uppercase text-4xl lg:text-5xl text-center`}>Babac'cool</div>
          <div className={`text-xl text-dark text-center my-6 leading-tight`}>
            Te remercie de ta confiance, retrouve ta fiche dans ton espace de révision.
          </div>
          <div className={`text-sm text-center mb-8 max-w-md mx-auto text-gray-800`}>
            PS : Rafraichis la page si l'article n'apparaît pas immédiatement, le traitement peut prendre quelques instants.
          </div>
          <Link
            to="/mon-espace"
            onClick={() => {
              setHide(true);
            }}
            className="block uppercase text-center p-3 w-full pl-4 text-white rounded-lg transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
          >
            Mon espace
          </Link>
        </div>
      ) : (
        <div className="sm:flex mx-auto my-auto flex-col justify-center sm:py-0 sm:my-0 lg:w-8/12 h-max sm:h-full z-10">
          <div className="text-2xl transform sm:text-3xl text-dark font-bold text-center pb-4 sm:pb-0">{typeArticle(article.type)}</div>
          <div className="text-xl sm:text-2xl text-dark font-semibold text-center pb-5">{article.name}</div>
          <div className="text-sm sm:text-base pb-6 text-center">{descriptionProduit(article)}</div>
          <div className="text-2xl text-center mb-5 sm:mb-0">{`Prix : ${price(article.pricing)}`}</div>
          {connected ? (
            disponible ? (
              <button
                onClick={() => freeSheet(article.id, setSuccess)}
                className="p-3 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
              >
                obtenir gratuitement
              </button>
            ) : (
              <button
                className="p-3 mb-5 sm:mb-0 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
                onClick={() => setCheckout(true)}
              >
                {article.type === "fiche" && "Acheter cette fiche"}
                {article.type === "chapitre" && "Acheter le pack chapitre"}
                {article.type === "matiere" && "Acheter le pack matière"}
              </button>
            )
          ) : (
            <>
              {/* <ShowAuth
            login={true}
            redirect={false}
            className="p-3 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
          >
            Connecte-toi
          </ShowAuth> */}
              <button
                onClick={() => {
                  setHide(false);
                  setOpenLogin(true);
                }}
                className="p-3 w-full pl-4 text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
              >
                Connecte-toi
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

// export const AchatModal = ({ achat, setAchat, connected }) => {
//   const { width } = useViewport();
//   const [checkout, setChekout] = useState(false);
//   const data = useQueryClient().getQueryData(["sheetsStructure"]);
//   const article = articleInfos(achat.type, achat.id, data);

//   const portal = document.getElementById("portal-root");

//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     window.scrollTo(0, 0);
//     return () => {
//       document.body.style.overflow = "unset";
//     };
//   }, []);

//   return createPortal(
//     <>
//       {achat && (
//         <div className="fixed top-0 left-0 w-screen h-screen sm:flex justify-center items-center py-10 overflow-scroll bg-white sm:bg-transparent">
//           <div
//             className="hidden sm:block sm:fixed top-0 left-0 w-full h-full bg-black opacity-60 z-0"
//             onClick={() => setAchat(false)}
//           />
//           <div className="bg-white max-w-xl rounded-3xl z-10 p-10">
//             <div className="text-3xl transform sm:text-3xl text-dark font-bold text-center pb-4 sm:pb-0">
//               {typeArticle(article.type)}
//             </div>
//             <div className="text-2xl text-dark font-semibold text-center pb-5">
//               {article.name}
//             </div>
//             <div className="text-sm sm:text-base pb-6">
//               {descriptionProduit(article)}
//             </div>
//             <div className="text-2xl text-center mb-5 sm:mb-0">{`Prix : ${price(
//               article.pricing
//             )}`}</div>
//           </div>
//         </div>
//       )}
//     </>,
//     portal
//   );
// };

export const AchatModal1 = ({ achat, setAchat, connected }) => {
  const { width } = useViewport();
  const [checkout, setChekout] = useState(false);
  const [hide, setHide] = useState(false);
  const data = useQueryClient().getQueryData(["sheetsStructure"]);
  const article = articleInfos(achat.type, achat.id, data);

  const portal = document.getElementById("portal-root");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return createPortal(
    <>
      {achat && (
        <div
          className={`${
            hide ? "hidden" : "block sm:flex"
          } fixed top-0 left-0 w-screen h-screen justify-center items-center overflow-scroll bg-white sm:bg-transparent`}
        >
          <div className="hidden sm:block sm:fixed top-0 left-0 w-full h-full bg-black opacity-60 z-0" onClick={() => setAchat(false)} />
          <div
            style={{
              backgroundColor: width > 640 ? "rgb(230, 230, 230)" : "white",
            }}
            className="w-full relative sm:w-max-content max-w-screen-md h-screen overflow-y-auto sm:h-auto sm:rounded-2xl m-0 sm:m-4 md:m-0 z-10 flex justify-center items-center p-8 sm:p-10"
          >
            <div className="absolute right-2 top-2 cursor-pointer" onClick={() => setAchat(false)}>
              <svg className="w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <img className="hidden md:block w-5/12 max-w-6xl mr-10" src={achatImg} alt="" />
            {!checkout ? (
              <ArticleDescription article={article} connected={connected} setCheckout={setChekout} />
            ) : (
              <CheckoutForm article={article} setHide={setHide} />
            )}
          </div>
        </div>
      )}
    </>,
    portal
  );
};

export const AchatModal = ({ achat, setAchat, connected, setHide, setOpenLogin }) => {
  const data = useQueryClient().getQueryData(["sheetsStructure"]);
  const article = articleInfos(achat.type, achat.id, data);
  const [checkout, setChekout] = useState(false);
  return (
    <div className="absolute top-0 min-h-full left-0 right-0 rounded-xl flex justify-center items-center p-4 sm:p-10 md:p-16">
      <img className="hidden lg:block w-4/12 max-w-6xl mr-10 z-10" src={achatImg} alt="" />
      {!checkout ? (
        <ArticleDescription article={article} connected={connected} setCheckout={setChekout} setHide={setHide} setOpenLogin={setOpenLogin} />
      ) : (
        <CheckoutForm article={article} setHide={setHide} />
      )}
    </div>
  );
};

// export const AchatModal = ({ achat, setAchat, connected }) => {
//   const { width } = useViewport();
//   const [checkout, setChekout] = useState(false);
//   const data = useQueryClient().getQueryData(["sheetsStructure"]);
//   const article = articleInfos(achat.type, achat.id, data);

//   const portal = document.getElementById("portal-root");

//   useEffect(() => {
//     document.body.style.overflow = "hidden";
//     window.scrollTo(0, 0);
//     return () => {
//       document.body.style.overflow = "unset";
//     };
//   }, []);

//   return createPortal(
//     <>
//       {achat && (
//         <div className="fixed sm:absolute top-0 left-0 w-screen h-screen flex justify-center items-center">
//           <div
//             className="absolute w-full h-full bg-black opacity-60 z-0"
//             onClick={() => setAchat(false)}
//           />
//           <div
//             style={{
//               backgroundColor: width > 640 ? "rgb(230, 230, 230)" : "white",
//             }}
//             className="w-full relative sm:w-max-content max-w-screen-md h-screen overflow-y-auto sm:h-auto sm:rounded-2xl m-0 sm:m-4 md:m-0 z-10 flex justify-center items-center p-8 sm:p-10"
//           >
//             <div
//               className="absolute right-2 top-2 cursor-pointer"
//               onClick={() => setAchat(false)}
//             >
//               <svg
//                 className="w-12"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </div>
//             <img
//               className="hidden md:block w-5/12 max-w-6xl mr-10"
//               src={achatImg}
//               alt=""
//             />
//             {!checkout ? (
//               <ArticleDescription
//                 article={article}
//                 connected={connected}
//                 setCheckout={setChekout}
//               />
//             ) : (
//               <CheckoutForm article={article} />
//             )}
//           </div>
//         </div>
//       )}
//     </>,
//     portal
//   );
// };
