import React from "react";
import instaWord from "../../assets/instagram-wordmark-white.svg";
import tiktok from "../../assets/TikTok-logo.svg";

const Footer = () => {
  return (
    <div className="relative">
      <div className="flex flex-col sm:flex-row items-center sm:items-around justify-center sm:justify-around bg-gradient-to-r from-purple-elec to-purple-pastel h-40 sm:h-28 left-0 right-0 bottom-0 absolute">
        <div className="flex items-center">
          <a
            aria-label="Notre Instagram"
            href="https://www.instagram.com/babac_cool/?hl=fr"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={instaWord}
              className="h-24 transform translate-y-1 -my-10"
              alt=""
            />
          </a>
          <a
            aria-label="Notre TikTok"
            href="https://www.tiktok.com/@babac_cool?lang=fr"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tiktok} className="h-20 -my-3" alt="" />
          </a>
        </div>
        <div className="flex items-center justify-center">
          <img
            src="https://img.icons8.com/metro/26/ffffff/copyright.png"
            className="h-4 w-4"
            alt=""
          />
          <div className="text-white ml-2 text-center w-max text-sm sm:text-base">
            Tous droits réservés à
            <span className="font-fredoka uppercase tracking-widest">
              {" Babac'cool "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
