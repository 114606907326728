import React from "react";
import { UserContext } from "../../components/utilities/UserContext";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { deleteTokens } from "../utilities/IsConnected";
import { useGoogleLogout } from "react-google-login";

const MonCompte = ({ setOpen }) => {
  const { userInfos, logout } = React.useContext(UserContext);
  const history = useHistory();
  const queryClient = useQueryClient();

  const onGoogleLogoutSuccess = () => {
    console.log("logout");
  };
  const onGoogleLogoutFailure = () => {
    console.log("logout fail");
  };
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_ID,
    onLogoutSuccess: onGoogleLogoutSuccess,
    onFailure: onGoogleLogoutFailure,
  });

  const logoutAndDeleteTokens = (history) => {
    logout();
    signOut();
    deleteTokens().then(() => {
      queryClient.invalidateQueries(
        "ownedSheetsStructure",
        "possessions",
        "offre"
      );
    });
    history.push("/");
  };
  const unlimited = userInfos.offer !== false;
  const arrayDate = unlimited ? userInfos.offer?.date.split("/") : [0, 0, 0];
  const jour = parseInt(arrayDate[0]) === 1 ? 1 : parseInt(arrayDate[0]) - 1;
  const mois = parseInt(arrayDate[1]);
  const annee = parseInt(arrayDate[2]) + 1;
  const stringMois = (mois) => {
    switch (mois) {
      case 1:
        return "janvier";
      case 2:
        return "fevrier";
      case 3:
        return "mars";
      case 4:
        return "avril";
      case 5:
        return "mai";
      case 6:
        return "juin";
      case 7:
        return "juillet";
      case 8:
        return "aout";
      case 9:
        return "septembre";
      case 10:
        return "octobre";
      case 11:
        return "novembre";
      case 12:
        return "décembre";
      default:
        return "janvier";
    }
  };
  const date = `${jour} ${stringMois(mois)} ${annee}`;

  return (
    <div className="flex flex-col absolute rounded bg-white pt-5 w-72 shadow-xl">
      <div
        className="absolute right-2 top-2 cursor-pointer"
        onClick={() => setOpen(false)}
      >
        <svg
          className="w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="text-sm text-gray-400 mb-1 mx-5">Nom d'utilisateur :</div>
      <div className="mx-5">{userInfos.name}</div>
      <div className="text-sm text-gray-400 mt-4 mb-1 mx-5">
        Adresse e-mail :
      </div>
      <div className={`${!unlimited && "mb-5"} mx-5`}>{userInfos.email}</div>
      {unlimited && (
        <div className="justify-around items-center py-3 px-5 mt-5 bg-purple-600 text-white">
          <div className="text-xl font-bold">
            Accès{" "}
            <span className="text-2xl font-fredoka tracking-wider">
              illimité
            </span>
          </div>
          <div>
            <div className="text-sm mb-1">Jusqu'au : {date}</div>
          </div>
        </div>
      )}
      <div className={`${unlimited && "bg-purple-600"} rounded-b`}>
        <div
          onClick={() => logoutAndDeleteTokens(history)}
          className="flex justify-center items-center cursor-pointer mb-5 shadow-lg h-10 rounded text-white bg-red-elec hover:bg-red-600 transition duration-300 mx-5"
        >
          <p className="mr-3">DÉCONNEXION</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default MonCompte;
