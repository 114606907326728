import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { axiosAuth } from "./axiosConfig";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";
import googlePayLogo from "../../assets/Google_Pay_Logo.svg";
import applePayLogo from "../../assets/Apple_Pay_logo.svg";
import edgeLogo from "../../assets/Edge_logo.svg";

const typeArticle = (type) => {
  switch (type) {
    case "matiere":
      return "Pack matiere :";
    case "chapitre":
      return "Pack chapitre :";
    case "fiche":
      return "Fiche :";
    default:
      break;
  }
};

const CustomPaymentRequestButton = ({ type, paymentRequest }) => {
  return (
    <button
      className={`w-full text-xl tracking-tight text-white rounded-lg transition duration-300 bg-black hover:bg-gray-800 focus:outline-none mt-3 h-12 my-3 flex justify-center items-center cursor-pointer`}
      onClick={() => paymentRequest.show()}
    >
      <img src={type === "Apple Pay" ? applePayLogo : type === "Edge" ? edgeLogo : googlePayLogo} className={type === "edge" ? "h-5" : "h-6"} />
      {type === "Edge" && <span className="ml-2">Pay</span>}
    </button>
  );
};

export default function CheckoutForm({ article, setHide }) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [price, setPrice] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [google, setGoogle] = useState(false);

  const { userAgent } = navigator;
  let browser = "";

  if (userAgent.includes("Firefox/")) {
    browser = "Firefox";
  } else if (userAgent.includes("Edg/")) {
    browser = "Edge";
  } else if (userAgent.includes("Chrome/")) {
    browser = "Chrome";
  } else if (userAgent.includes("Safari/")) {
    browser = "Safari";
  }

  const queryClient = useQueryClient();

  useEffect(() => {
    if (premium && succeeded) {
      queryClient.invalidateQueries("ownedSheetsStructure", "possessions", "offre");
    }
  }, []);

  // Google Pay and Apple Pay
  useEffect(() => {
    if (stripe && elements && price !== "") {
      const pr = stripe.paymentRequest({
        country: "FR",
        currency: "eur",
        total: {
          label: product,
          amount: price,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.

      pr.canMakePayment().then((result) => {
        setGoogle(JSON.stringify(result));
        if (result) {
          setPaymentRequest(pr);
          setRequestType(result.applePay === true ? "Apple Pay" : result.googlePay === true ? "Google Pay" : browser);
        }
      });

      pr.on("paymentmethod", async (event) => {
        // event.paymentMethod is available
        const result = await axiosAuth.post(`/payment/create-payment-intent`, {
          type: article.type,
          id: article.id,
        });

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          result.data.clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false }
        );

        if (error) {
          event.complete("fail");
          return;
        }

        const success = paymentIntent?.status === "succeeded";
        setSucceeded(success);
        event.complete("success");
        // if(paymentIntent.status === 'requires_action') {
        //   stripe.confirmCardPayment(clientSecret)
        // }
      });
    }
  }, [stripe, elements, price]);

  useEffect(async () => {
    const apiUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const result = await axios.post(`${apiUrl}/payment/pricing`, {
      type: article.type,
      id: article.id,
    });
    setProduct(result.data.product);
    setPrice(result.data.price);
  }, []);

  const premium = article.type === "offre" && article.id === 1;
  const color = "rgb(235, 235, 235)"; //premium ? "rgb(196, 181, 253)" : "rgb(235, 235, 235)";

  const cardStyle = {
    style: {
      base: {
        color: "rgb(44, 43, 46)",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "rgb(156, 163, 175)",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const result = await axiosAuth.post(`/payment/create-payment-intent`, {
      type: article.type,
      id: article.id,
    });
    const clientSecret = result.data.clientSecret;

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: userName,
          email: email,
        },
      },
    });

    setSucceeded(payload?.paymentIntent?.status === "succeeded");

    console.log(payload?.paymentIntent?.status);

    if (payload.error) {
      setError(`${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
    }
  };

  return (
    <div className="w-full mx-auto my-auto z-10">
      {succeeded ? (
        <>
          <div
            className={`font-fredoka ${
              premium ? "text-white" : "text-purple-elec"
            } tracking-wider text-bold uppercase text-4xl lg:text-5xl text-center`}
          >
            Babac'cool
          </div>
          <div className={`text-xl ${premium ? "text-white" : "text-dark"} text-center my-6 leading-tight`}>
            Te remercie de ta confiance, retrouve
            {premium && " toutes les fiches de l'accès illimité "}
            {article.type === "fiche" && " ta fiche "}
            {article.type === "chapitre" && " ton chapitre "}
            {article.type === "matiere" && " ta matière "}
            dans ton espace de révision.
          </div>
          <div className={`text-sm text-center mb-8 max-w-md mx-auto ${premium ? "text-gray-100" : "text-gray-800"}`}>
            PS : Rafraichis la page si l'article n'apparaît pas immédiatement, le traitement peut prendre quelques instants.
          </div>
          <Link
            to="/mon-espace"
            onClick={() => {
              setHide(true);
            }}
            className="block uppercase text-center p-3 w-full pl-4 text-white rounded-lg transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900"
          >
            Mon espace
          </Link>
        </>
      ) : (
        <>
          {/* <div>{google}</div> */}
          <form id="payment-form" onSubmit={handleSubmit}>
            {premium ? (
              <div className="text-3xl font-bold text-center mb-6">
                Souscrire à<br />
                l'Accès <span className="text-4xl font-fredoka tracking-wider">illimité</span>
              </div>
            ) : (
              <div className="text-xl sm:text-2xl text-dark font-semibold text-center pb-7">{`${typeArticle(article.type)} ${article.name}`}</div>
            )}
            <input
              id="email"
              required
              placeholder="Adresse mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              style={{ backgroundColor: color }}
              className="w-full rounded-lg border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none bg-gray-50 text-dark h-12 px-3 mb-2"
            />
            <input
              id="name"
              required
              placeholder="Nom du titulaire"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              style={{ backgroundColor: color }}
              className="w-full rounded-lg border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none bg-gray-50 text-dark h-12 px-3 mb-2"
            />
            <div
              style={{ backgroundColor: color }}
              className="w-full rounded-lg border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none bg-gray-50 text-dark p-3 mb-2"
            >
              <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            </div>
            {error && (
              <div className="text-red-600 ml-1 -mt-1" role="alert">
                {error}
              </div>
            )}
            <button
              disabled={processing || disabled || succeeded}
              id="submit"
              className={`uppercase w-full text-white rounded-lg transition duration-300 bg-purple-elec focus:outline-none mt-3 h-12 flex justify-center items-center ${
                disabled ? "cursor-default hover:bg-purple-elec opacity-80" : "cursor-pointer hover:bg-purple-900"
              }`}
            >
              <span id="button-text">{processing ? "CHARGEMENT..." : `PAYER ${price / 100 + " €"}`}</span>
            </button>
          </form>
          {paymentRequest && (
            <>
              <div className={`flex justify-center items-center w-64 h-px mx-auto my-6 ${premium ? "bg-white" : "bg-gray-400"}`}>
                <span className={`px-4 ${premium ? "bg-purple-600" : "bg-gray-200 text-gray-600"}`}>{`Ou payer avec ${requestType}`}</span>
              </div>
              <CustomPaymentRequestButton type={requestType} paymentRequest={paymentRequest} />
              {/* <div className="mb-2 h-12 rounded-lg overflow-hidden z-30">
                <PaymentRequestButtonElement
                  className="h-full z-20"
                  options={{
                    paymentRequest,
                    style: {
                      paymentRequestButton: {
                        type: "default",
                        // One of 'default', 'book', 'buy', or 'donate'
                        // Defaults to 'default'

                        theme: "dark",
                        // One of 'dark', 'light', or 'light-outline'
                        // Defaults to 'dark'

                        height: "3.5rem",
                        // Defaults to '40px'. The width is always '100%'.
                      },
                    },
                  }}
                />
              </div> */}
            </>
          )}
        </>
      )}
    </div>
  );
}
