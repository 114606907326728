import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MonCompte from "./MonCompte";
// import ShowAuth from "../Auth/ShowAuth";
import { UserContext } from "../utilities/UserContext";
import useViewport from "../utilities/useViewport";
import Modal from "../../components/utilities/Modal";
import Auth from "../Auth/Auth";

const NavLink = ({ to, children }) => {
  // const location = useLocation().pathname.split("/")[1];
  const active = false; // location === to.split("/")[1];
  return (
    <Link
      to={to}
      className={`lg:mx-5 font-semibold mx-3 text-base lg:text-lg text-gray-800 transition duration-300 px-1 py-1 border-b-2 ${
        active ? "border-purple-elec text-purple-elec cursor-default" : "border-white hover:border-purple-elec hover:text-purple-elec"
      }`}
    >
      {children}
    </Link>
  );
};

const RegisterLink = ({ setOpen, setLogin }) => {
  const { width } = useViewport();
  return (
    <>
      {width > 1024 ? (
        <>
          <button
            onClick={() => {
              setOpen(true);
              setLogin(false);
            }}
            className="bg-white focus:outline-none uppercase text-sm font-bold px-3 py-2 mr-2 text-purple-elec rounded border border-white hover:border-purple-elec transition duration-200"
          >
            inscription
          </button>
          <button
            onClick={() => {
              setOpen(true);
              setLogin(true);
            }}
            className="bg-purple-elec focus:outline-none uppercase text-sm font-semibold px-3 py-2 text-white rounded hover:bg-purple-800 transition duration-200"
          >
            connexion
          </button>
          {/* <ShowAuth
            login={false}
            className="bg-white focus:outline-none uppercase text-sm font-bold px-3 py-2 mr-2 text-purple-elec rounded border border-white hover:border-purple-elec transition duration-200"
          >
            Inscription
          </ShowAuth>
          <ShowAuth
            login={true}
            className="bg-purple-elec focus:outline-none uppercase text-sm font-semibold px-3 py-2 text-white rounded hover:bg-purple-800 transition duration-200"
          >
            Connexion
          </ShowAuth> */}
        </>
      ) : (
        <>
          <button
            onClick={() => {
              setOpen(true);
              setLogin(true);
            }}
            className="text-purple-elec h-10 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
          </button>
          {/* <ShowAuth
          login={true}
          className="text-purple-elec h-10 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
            />
          </svg>
        </ShowAuth> */}
        </>
      )}
    </>
  );
};

const NavbarDesktop = () => {
  const { userInfos } = React.useContext(UserContext);
  const { connected } = userInfos;
  return (
    <div className="lg:flex hidden w-full items-center justify-between">
      <div>
        <NavLink to="/">Accueil</NavLink>
        <NavLink to="/contact">Contact</NavLink>
        <NavLink to="/fiches">Fiches</NavLink>
        {connected && <NavLink to="/mon-espace">Mon espace</NavLink>}
      </div>
    </div>
  );
};

const NavLinkMobile = ({ to, children }) => {
  return (
    <Link to={to} className="text-lg sm:text-lg font-semibold text-gray-800 py-3 px-5 w-full">
      {children}
    </Link>
  );
};

const Hamburger2 = ({ setOpen, isOpen }) => {
  const { width } = useViewport();
  return (
    <button
      aria-label="Menu"
      onClick={() => setOpen(!isOpen)}
      className={`focus:outline-none ${width < 350 ? "ml-0" : "ml-3"} mr-4 flex items-center justify-center lg:hidden cursor-pointer text-dark`}
    >
      <div className="flex flex-col w-7 h-6 justify-between">
        <div className="w-full h-1/4 rounded-sm bg-blue-pastel" />
        <div className="w-full h-1/4 rounded-sm bg-purple-pastel" />
        <div className="w-full h-1/4 rounded-sm bg-purple-elec" />
      </div>
    </button>
  );
};

const NavbarMobile = ({ isOpen, setOpen }) => {
  const { userInfos } = React.useContext(UserContext);
  const { connected } = userInfos;
  const [hide, setHide] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!isOpen) {
  //       setHide(true);
  //     }
  //   }, 300);

  //   if (isOpen) {
  //     // document.body.style.overflow = "hidden";
  //     document.body.style.position = "fixed";
  //     document.body.style.left = 0;
  //     document.body.style.right = 0;
  //     setHide(false);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //     document.body.style.position = "";
  //     document.body.style.overflow = "";
  //   };
  // }, [isOpen]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (!isOpen) {
  //       setHide(true);
  //     }
  //   }, 300);

  //   if (isOpen) {
  //     const position = window.pageYOffset;
  //     document.body.style.position = "fixed";
  //     document.body.style.top = `-${position}px`;
  //     // document.body.style.left = `calc(${position} + 100vh)`;
  //     document.body.style.left = 0;
  //     document.body.style.right = 0;
  //     setHide(false);

  //     // FIXED HEADER - REMOVE IF ABSOLUTE
  //     document.getElementById("header").style.position = `absolute`;
  //     document.getElementById("header").style.top = `${position}px`;
  //   } else {
  //     const scrollY = -parseInt(document.body.style.top || "0");
  //     document.body.style.position = "";
  //     document.body.style.top = "";
  //     window.scrollTo(0, scrollY);

  //     // FIXED HEADER - REMOVE IF ABSOLUTE
  //     document.getElementById("header").style.position = "";
  //     document.getElementById("header").style.top = "";
  //   }
  //   return () => {
  //     clearTimeout(timer);
  //     document.body.style.position = "";
  //     document.body.style.overflow = "";
  //   };
  // }, [isOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isOpen) {
        setHide(true);
      }
    }, 300);
    if (isOpen) {
      setHide(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  return (
    <>
      <div
        onClick={() => setOpen(false)}
        className={`lg:hidden fixed top-0 left-0 h-full right-0 min-h-screen w-full bg-black z-20 transition duration-300 ${
          hide ? "invisible" : "visible"
        } ${isOpen ? "bg-opacity-70" : "bg-opacity-0"}`}
        // style={{ height: "calc(100vh + env(safe-area-inset-bottom))" }}
      />
      <div
        className={`lg:hidden fixed top-0 left-0 h-full min-h-screen max-w-max transition duration-300 z-20 ${
          isOpen ? "transform-gpu -translate-x-0" : "transform -translate-x-full"
        }`}
      >
        <div className="h-full w-full bg-gray-50 flex flex-col">
          <div className="flex items-center">
            <div onClick={() => setOpen(false)} className="h-18 sm:h-20 px-5 flex items-center justify-start cursor-pointer bg-white">
              <div className="mr-4">
                <div style={{ width: "26px" }} className="flex flex-col w-6 h-7 justify-between">
                  <div className="w-3/4 h-1/4 rounded-sm bg-blue-pastel transform -rotate-45 translate-y-1.5 z-10" />
                  <div className="w-full h-1/4 rounded-sm bg-purple-pastel transform translate-x-1" />
                  <div className="w-3/4 h-1/4 rounded-sm bg-purple-elec transform rotate-45 -translate-y-1.5 z-10" />
                </div>
              </div>
              <div className="text-3xl font-fredoka text-purple-elec tracking-wide font-bold">BABAC'COOL</div>
            </div>
          </div>
          <div className="w-full h-px bg-gray-200" />
          <div className="flex flex-col w-full" onClick={() => setOpen(false)}>
            <NavLinkMobile to="/">Accueil</NavLinkMobile>
            <NavLinkMobile to="/contact">Contact</NavLinkMobile>
            <NavLinkMobile to="/fiches">Fiches</NavLinkMobile>
            {connected && <NavLinkMobile to="/mon-espace">Mon espace</NavLinkMobile>}
          </div>
        </div>
      </div>
    </>
  );
};
const MyAccount = () => {
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!open) {
        setHide(true);
      }
    }, 300);

    if (open) {
      setHide(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <>
      <button onClick={() => setOpen(!open)} className="focus:outline-none flex justify-center items-center text-purple-elec">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 lg:h-10 lg:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
      <div
        className={`z-10 relative transition duration-300 transform -translate-x-72 ${hide ? "invisible" : "visible"} ${
          open ? "translate-y-12 opacity-100" : "translate-y-5 opacity-0"
        }`}
      >
        <MonCompte setOpen={setOpen} />
      </div>
    </>
  );
};

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [login, setLogin] = useState(false);
  const { userInfos } = React.useContext(UserContext);
  const { connected } = userInfos;

  return (
    <>
      <div id="header" className="fixed bg-white w-full shadow-md z-20">
        <header className="flex max-w-screen-xl m-auto items-center px-3 sm:px-5 h-18 sm:h-20">
          <Hamburger2 toggled={isOpen} setOpen={setOpen} />
          <Link
            to="/"
            aria-label="Accueil"
            onClick={() => setOpen(false)}
            className="flex text-3xl md:w-96 sm:text-3xl font-fredoka text-purple-elec tracking-wide font-bold mr-auto lg:mr-10"
          >
            <div className="lg:flex hidden flex-col items-start justify-center ml-3 mr-4">
              <div className="flex w-8 h-8 justify-between">
                <div className="w-1/4 h-full rounded-sm bg-purple-elec" />
                <div className="w-1/4 h-full rounded-sm bg-purple-pastel" />
                <div className="w-1/4 h-full rounded-sm bg-blue-pastel transform -rotate-12 translate-x-0.5" />
              </div>
              <div style={{ height: "3px", width: "37px" }} className="rounded-sm bg-blue-pastel mt-px transform" />
            </div>
            BABAC'COOL
          </Link>
          {/* <div className="lg:flex hidden flex-col items-start justify-center p-4 border border-black">
            <div className="flex w-8 h-8 justify-between">
              <div className="w-1/4 h-full rounded-sm bg-purple-elec" />
              <div className="w-1/4 h-full rounded-sm bg-purple-pastel" />
              <div className="w-1/4 h-full rounded-sm bg-blue-pastel transform -rotate-12 translate-x-0.5" />
            </div>
            <div style={{ height: "3px", width: "37px" }} className="rounded-sm bg-blue-pastel mt-px transform" />
          </div> */}
          <NavbarDesktop />
          {connected ? (
            <MyAccount />
          ) : (
            <div className="flex">
              <RegisterLink to="/" login={true} setOpen={setOpenLogin} setLogin={setLogin} />
            </div>
          )}
        </header>
      </div>
      <NavbarMobile isOpen={isOpen} setOpen={setOpen} />
      <Modal open={openLogin} setOpen={setOpenLogin}>
        <Auth setOpen={setOpenLogin} login={login} redirect={true}></Auth>
      </Modal>
      <div className="h-18 sm:h-20 bg-white"></div>
    </>
  );
};

export default Header;
