import React, { useState, useEffect } from "react";
import { axiosApi } from "../components/utilities/axiosConfig";
import { useLocation } from "react-router-dom";
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import { motion } from "framer-motion";
import Modal from "../components/utilities/Modal";
import Auth from "../components/Auth/Auth";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  border-color: red;
`;

const Verification = () => {
  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  // const random = useLocation().pathname.split("/")[2];
  // const emailVerificationToken = localStorage.getItem("emailVerificationToken");
  const emailVerificationToken = useLocation().pathname.split("/")[2];

  useEffect(() => {
    axiosApi.post(`/auth/verify-email/${emailVerificationToken}`).then((res) => {
      if (res.data.verified === true) setSuccess(true);
    });
    let timerFunc = setTimeout(() => {
      setLoader(false);
    }, 2000);

    return () => clearTimeout(timerFunc);
  }, []);

  return (
    <div className="relative">
      {loader ? (
        <motion.div
          initial={{ opacity: 1, y: 0 }}
          animate={{
            opacity: 0,
            y: "-100px",
            transition: { delay: 1.7, ease: "easeOut" },
          }}
          className="mx-auto mt-20 w-max transition delay-150 duration-300 ease-in-out"
        >
          <SyncLoader color="#6B2FC2" css={override} size={20} margin={5} />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.7 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { delay: 0, ease: "easeOut" },
          }}
        >
          <div className="mx-auto w-max text-base sm:text-xl font-semibold text-dark mt-20 mb-10">
            {success ? "Ton adresse email a été vérifiée" : "Le lien que tu as utilisé n'es plus valide..."}
          </div>
          <div onClick={() => setOpen(true)}>
            <div className="h-12 p-3 w-max mx-auto flex px-20 justify-center items-center text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none mt-3 cursor-pointer hover:bg-purple-900">
              ME CONNECTER
            </div>
          </div>
        </motion.div>
      )}
      <Modal open={open} setOpen={setOpen}>
        <Auth setOpen={setOpen} login={true} redirect={true} />
      </Modal>
    </div>
  );
};

export default Verification;
