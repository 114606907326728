import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import StructureFiches from "./StructureFiches";
import { Link } from "react-router-dom";
import { axiosAuth } from "../../components/utilities/axiosConfig";
import { UserContext } from "../../components/utilities/UserContext";

const getOwnedSheetsStructure = async () => {
  const res = await axiosAuth.get(`/sheets/owned-sheets`, {
    withCredentials: true,
  });
  return res.data;
};
const getOwnedOffer = async () => {
  const res = await axiosAuth.get(`/auth/offre`, {
    withCredentials: true,
  });
  return res.data;
};

const MonEspace = () => {
  const [loader, setLoader] = useState(false);
  const { setPremium } = React.useContext(UserContext);

  const { data, status, isSuccess } = useQuery("ownedSheetsStructure", getOwnedSheetsStructure, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
  const offre = useQuery("offre", getOwnedOffer, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (offre.isFetchedAfterMount || offre.isSuccess) setPremium(offre.data);
  }, [offre.isFetchedAfterMount, offre.isSuccess]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isSuccess) {
        setLoader(false);
        document.body.style.overflow = "";
      } else {
        setLoader(true);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);
  return (
    <div className="w-full z-10">
      <div className="flex justify-center items-center w-full bg-gradient-to-r from-orange-middle to-pink-middle">
        <div className="text-2xl font-normal tracking-widest lg:text-3xl font-fredoka text-white text-center px-8 py-4">MON ESPACE</div>
      </div>
      {loader && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-transparent -z-10">
          <div className="mx-auto w-max transition delay-150 duration-300">
            <div className="lds-ellipsis">
              <div style={{ backgroundColor: "#FFC488" }} />
              <div style={{ backgroundColor: "#FFC488" }} />
              <div style={{ backgroundColor: "#FFC488" }} />
              <div style={{ backgroundColor: "#FFC488" }} />
            </div>
          </div>
        </div>
      )}
      {status === "success" && data?.session === "connected" && (
        <React.Fragment>
          {JSON.stringify(data.content) === "[]" ? (
            <div className="max-w-screen-lg mx-auto my-10 flex flex-col sm:flex-row justify-center items-center">
              <div className="text-xl text-dark font-semibold sm:mr-10 mb-7 sm:mb-0 text-center">Vous n'avez pas encore de fiches !</div>
              <Link to="/fiches">
                <div className="p-3 w-max mx-auto flex px-10 justify-center items-center text-white rounded-lg uppercase transition duration-300 bg-purple-elec focus:outline-none cursor-pointer hover:bg-purple-900">
                  Voir les fiches
                </div>
              </Link>
            </div>
          ) : (
            <StructureFiches data={data.content} location="mon-espace" ownedData={false} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};
export default MonEspace;
