import React, { useState } from "react";

export const UserContext = React.createContext({
  connected: false,
  name: "",
  email: "",
});

export const UserProvider = ({ children }) => {
  // User is the name of the "data" that gets stored in context
  const [userInfos, setUserInfos] = useState({
    connected: false,
    name: "",
    email: "",
    offer: false,
  });

  // Login updates the user data with a name parameter
  const login = (name, email, offer) => {
    setUserInfos({
      connected: true,
      name: name,
      email: email,
      offer: offer,
    });
  };
  const setPremium = (offre) => {
    const prevState = userInfos;
    setUserInfos({
      connected: prevState.connected,
      name: prevState.name,
      email: prevState.email,
      offer: offre,
    });
  };

  // const setOffer = (offer) => {
  //   console.log();
  //   console.log({ ...userInfos, offer: offer });
  //   setUserInfos({ ...userInfos, offer: offer });
  // };

  // Logout updates the user data to default
  const logout = () => {
    setUserInfos({
      connected: false,
      name: "",
      email: "",
    });
  };

  return (
    <UserContext.Provider value={{ userInfos, login, logout, setPremium }}>
      {children}
    </UserContext.Provider>
  );
};
