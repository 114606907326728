import React, { useState, useEffect } from "react";
import contactImg from "../assets/contact.png";
import { axiosApi } from "../components/utilities/axiosConfig";

const Modal = ({ open, setOpen, setEmail, setMessage }) => {
  useEffect(() => {
    return () => {
      setEmail("");
      setMessage("");
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center">
      <div className="absolute w-full h-full bg-black opacity-60 z-20 cursor-pointer" onClick={() => setOpen(false)} />
      <div className="w-11/12 relative sm:w-max-content max-w-screen-sm rounded-xl bg-white z-30 flex justify-center items-center p-12">
        <div className="absolute right-2 top-2 cursor-pointer" onClick={() => setOpen(false)}>
          <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className="text-dark text-lg text-center">Ton avis nous a bien été envoyé, on te répond au plus vite</div>
      </div>
    </div>
  );
};

const Contact = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (email !== "" && message !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, message]);

  const onSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    axiosApi
      .post(`/contact`, {
        email: email,
        message: message,
      })
      .then((res) => console.log(res));
  };

  return (
    <div className="contact-bg">
      {open && <Modal setOpen={setOpen} setEmail={setEmail} setMessage={setMessage} />}
      <div className="w-full px-3 sm:px-4 py-3 sm:py-20">
        <div className="flex md:justify-between justify-center max-w-screen-lg mx-auto w-full h-full bg-white rounded-2xl shadow-2xl">
          <div className="md:flex hidden justify-center items-center w-1/2">
            <img className="object-cover" src={contactImg} alt="" />
          </div>
          <div className="flex flex-col w-11/12 md:w-1/2 py-10 px-1 sm:px-5">
            <div className="text-3xl text-center font-semibold text-dark tracking-wide">Contacte-nous</div>
            <div className="text-base text-center mx-5 mt-3 mb-4 text-gray-500">
              Tu as des questions ? <br />
              Des suggestions à nous faire ? <br />
              Dis-nous tout et on te répond vite !
            </div>
            <form onSubmit={onSubmit}>
              <input
                className="w-full rounded-lg p-2 border border-gray-200 bg-gray-50 focus:bg-white focus:outline-none focus:border-gray-300"
                type="text"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Adresse e-mail"
              />
              <textarea
                className="w-full mt-6 h-60 rounded-lg p-2 border border-gray-200 bg-gray-50 focus:bg-white resize-none focus:outline-none focus:border-gray-300"
                id="text-area"
                placeholder="Entrez votre question ici"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                rows="4"
                cols="50"
              ></textarea>
              <input
                className={`block rounded-lg mt-6 md:w-64 w-full mx-auto text-white py-3 transition duration-150 ${
                  disabled ? "bg-orange-pastel cursor-not-allowed" : "bg-orange-middle hover:bg-yellow-600 cursor-pointer"
                }`}
                type="submit"
                value="ENVOYER"
                disabled={disabled}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
