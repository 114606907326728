import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { axiosApi } from "../utilities/axiosConfig";
import logoFb from "../../assets/logo-fb.svg";

const LoginWithFacebook = ({ setSuccess, setSocialEmailError }) => {
  const responseFacebook = (response) => {
    axiosApi
      .post(
        `/auth/facebook-login`,
        {
          idToken: response.userID,
          accessToken: response.accessToken,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success === true) {
          console.log("FACEBOOK SIGNIN SUCCESS");
          setSuccess({
            user: {
              name: res.data.name,
              email: res.data.email,
              refreshToken: res.data.refreshToken,
              accessToken: res.data.accessToken,
              offer: res.data.offer,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        const message = error?.response.data.error;

        if (message === "email indisponible") setSocialEmailError(true);
        console.log("FACEBOOK SIGNIN ERROR", error);
      });
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_ID}
      // redirectUri="https://babaccool.fr"
      disableMobileRedirect={true}
      autoLoad={false}
      callback={responseFacebook}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          className="focus:outline-none w-full"
        >
          <div className="flex justify-start items-center cursor-pointer h-12 my-2 rounded-lg transition duration-300 bg-gray-50 border border-gray-300 hover:bg-gray-200 hover:border-gray-400">
            <img
              className="w-6 lg:ml-24 ml-16 mr-5"
              src={logoFb}
              alt={"logo de google"}
            />
            <div className="font-semibold">Facebook</div>
          </div>
        </button>
      )}
    />
  );
};
export default LoginWithFacebook;
