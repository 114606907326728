import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosApi } from "../components/utilities/axiosConfig";
import { Link } from "react-router-dom";

// SCHEMA DE VÉRIFICATION
const schema = yup.object().shape({
  email: yup.string().email("Adresse e-mail invalide").required("Veuillez entrer une adresse e-mail."),
});

// COMPOSANT
function ForgotPassword() {
  const [success, setSuccess] = useState(false);

  // VÉRIFICATION
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // ENVOI DU FORMULAIRE
  const onSubmit = (data) => {
    console.log(data);
    axiosApi.post("/auth/forgot-password", { data }).then((res) => {
      console.log(res.data);
      setSuccess({ email: res.data.email });
    });
  };

  // FORMULAIRE
  return (
    <div
      style={{ minHeight: "calc(100vh - 80px)" }}
      className="w-full p-0 sm:px-4 py-3 sm:py-20 xl:py-32 bg-gray-50 flex justify-center items center"
    >
      <div className="flex max-w-2xl flex-col md:justify-between justify-center mx-auto w-full h-max bg-white sm:rounded-xl sm:shadow-2xl p-5 sm:p-10">
        <div className="text-3xl sm:text-4xl font-bold text-purple-elec text-center mb-10">Mot de passe oublié ?</div>
        {!success ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <div>
                <div style={{ color: "red" }} className={`block -mb-2 text-sm ${errors.email && "-mt-5"}`}>
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
                <input
                  type="text"
                  name="email"
                  ref={register}
                  placeholder="Adresse e-mail"
                  style={{ border: errors.email && "1px solid red" }}
                  className="h-12 my-2 w-full pl-4 rounded-lg bg-gray-50 border border-gray-300 focus:bg-white focus:border-gray-400 focus:outline-none"
                />
              </div>
              <input
                type="submit"
                value="ENVOYER"
                className="h-12 cursor-pointer my-2 pl-4 text-white rounded-lg transition duration-300 bg-purple-elec hover:bg-purple-900"
              />
            </form>
            <p className="text-sm text-gray-400 text-center my-3">
              En cliquant sur envoyer, tu confirmes la demande de réinitialisation de mot de passe
            </p>
          </>
        ) : (
          <div className="flex flex-col justify-center items-center w-full">
            <div className="text-xl font-semibold mb-2 text-dark text-center">{`Nous avons envoyé un e-mail à ${success.email},`}</div>
            <div className="text-lg text-dark mb-10 text-center">Va voir dans tes mails pour réinitialiser ton mot de passe</div>
            <Link
              to="/"
              className="h-12 flex justify-center items-center cursor-pointer w-full my-2 text-white rounded-lg transition duration-300 bg-purple-elec hover:bg-purple-900"
            >
              <p className="text-lg">ACCUEIL</p>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
